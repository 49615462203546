.unordered-list-default, .unordered-list-primary, .ordered-list-default {
  li {
    margin-bottom: 9px;
    font-size: 18px;
    font-family: $secondary-font;
    position: relative;
    a {
      color: $lightblue;
      &:hover {
        text-decoration: underline;
        text-decoration-color: #FFD350;
        text-decoration-thickness: 3px;
      }
    }
  }
}
.unordered-list-default {
  list-style-type: none;
  li {
    &::marker {
      color: $lightblue;
    }
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background: $lightblue;
      border-radius: 50%;
      left: -17px;
      top: 9px;
      position: absolute;
    }
    ul li {
      &::marker {
        color: $defaultblue;
      }
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: $white;
        border: solid 2px $defaultblue;
        border-radius: 50%;
        left: -17px;
        top: 9px;
        position: absolute;
      }
    }
  }
  ul {
    list-style: none;
  }
  &.text-white {
    li {
      &::marker {
        color: $white;
      }
      &::before {
        background: $white;
      }
    }
  }
}

.unordered-list-primary {
  li {
    &::marker {
      color: $defaultblue;
    }
    ul li {
      margin-left: 30px;
    }
  }
}

.ordered-list-default {
  li {
    &::marker {
      font-family: $secondary-font;
      font-weight: 900;
    }
    ul {
      list-style-type: lower-latin;
    }
  }
}
.list-two-columns {
  @media only screen and (min-width: 768px) {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  .btn {
    margin: 8px 0;
  }
  &.text-white {
    a {
      color: $white;
    }
  }
  &.list-style-none {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin: 8px 0;
      &:first-child {
        margin: 0;
      }
      .btn {
        margin: 0;
      }
    }
  }
}
