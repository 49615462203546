
.our-programs-container {
    padding: 60px 0;
    @media only screen and (min-width: 769px) {
        padding: 60px;
    }
    .our-programs-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        @media only screen and (max-width: 768px) {
            display: block;
        }
        li {
            flex: 1 0 30%;
            margin: 5px;
            @media only screen and (max-width: 768px) {
                margin-bottom: 30px;
            }
            .our-programs-title {
                line-height: 20px;
                a {
                    font-family: $primary-bold-font;
                    font-size: 20px;
                    color: $defaultblue;
                    text-decoration: none;
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
            a.img-hover-zoom-link {
                text-decoration: none;
                .img-hover-zoom {
                    overflow: hidden;
                    margin-bottom: 6px;
                    box-shadow: 0 1px 1px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%), 0 8px 8px rgb(0 0 0 / 15%);
                    position: relative;
                    img {
                        transition: transform .5s ease;
                        max-width: inherit;
                        width: 100%;
                        vertical-align: middle;
                    }
                    .content-overlay {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-image: linear-gradient(transparent, black, black);
                        color: $white;
                        padding: 15px;
                        @media only screen and (max-width: 768px) {
                            position: initial;
                            background-image: linear-gradient($off-black, $off-black);
                            padding: 17px 27px;
                        }
                        .content-title {
                            font-family: $primary-bold-font;
                            font-size: 18px;
                            text-decoration: underline;
                        }
                        .content-excerpt {
                            font-size: 14px;
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            padding: 0;
                            margin: -1px;
                            overflow: hidden;
                            clip: rect(0, 0, 0, 0);
                            white-space: nowrap;
                            border: 0;
                            text-decoration: none;
                            @media only screen and (max-width: 768px) {
                                font-size: 14px;
                                position: initial;
                                width: auto;
                                height: auto;
                                padding: 0;
                                margin: 10px 0 0;
                                overflow: auto;
                                clip: initial;
                                white-space: initial;
                            }
                        }
                    }
                }
                &:hover, &:focus {
                    .img-hover-zoom {
                        outline: solid 2px $red;
                        .content-overlay {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            background-color: #222222;
                            color: $white;
                            padding: 15px;
                            background-image: none;
                            @media only screen and (max-width: 768px) {
                                position: initial;
                                padding: 17px 27px;
                            }
                            .content-excerpt {
                                font-size: 14px;
                                position: initial;
                                width: auto;
                                height: auto;
                                padding: 0;
                                margin: 10px 0 0;
                                overflow: auto;
                                clip: initial;
                                white-space: initial;
                            }
                        }
                    }
                }
            }
            &:hover, &:focus-within {
                .our-programs-title {
                    a {
                        text-decoration: underline;
                    }
                }
                a.img-hover-zoom-link {
                    .img-hover-zoom {
                        outline: solid 2px $red;
                    }
                }
            }
        }
    }
}