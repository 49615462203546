.sidebar-menu-wrap {
    background-color: transparent;
    margin-bottom: 27px;
    padding: 60px 0 0;

    @media only screen and (max-width: 576px) {
        background-color: transparent;
        margin-bottom: 27px;
        border: none;
        padding: 0;
        position: relative;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            display: none;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $off-black;
        border-bottom: solid 2px $off-black;
        font-size: 28px;
        line-height: 41px;
    }

    .sidebar-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            &.active {
                a {
                    background-color: $white;
                    color: $defaultblue;
                    border: solid 2px $defaultblue;

                    &::after {
                        display: inline-block;
                        margin-left: 8px;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f105";
                        line-height: 0;
                        font-size: 18px;
                        position: absolute;
                        right: 13px;
                        top: 27px;
                    }
                }
            }

            a {
                font-size: 18px;
                font-family: $primary-bold-font;
                color: $off-black;
                line-height: normal;
                margin-bottom: 8px;
                text-decoration: none;
                display: block;
                width: 100%;
                padding: 15px 35px 15px 13px;
                background-color: $white;
                border: solid 2px $white;
                position: relative;

                &:hover {
                    background-color: $white;
                    color: $defaultblue;
                    border: solid 2px $defaultblue;

                    &::after {
                        display: inline-block;
                        margin-left: 8px;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f105";
                        line-height: 0;
                        font-size: 18px;
                        position: absolute;
                        right: 13px;
                        top: 27px;
                    }
                }

                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
            }

            .sidebar-sub-menu {
                list-style-type: none;

                li {
                    a {
                        padding: 0;
                        border: none;
                        background-color: transparent;
                        font-family: $secondary-font;
                        font-size: 18px;
                        line-height: 28px;
                        color: $defaultblue;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }

                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 576px) {
            display: none;
        }
    }

    select {
        background-color: $white;
        font-size: 18px;
        font-family: $primary-bold-font;
        color: $defaultblue;
        width: 100%;
        padding: 12px 40px 12px 8px;
        border: $defaultblue solid 3px;
        border-radius: 0;
        position: relative;
        background-image: url(../../../assets/images/in_this_section.svg);
        background-repeat: no-repeat;
        background-position: 97% 24px;
        background-size: 13px 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        display: none;

        @media only screen and (max-width: 576px) {
            display: block;
        }

        option {
            font-size: 18px;
        }
    }

    &.sidebar-menu-wrap-secondary {
        padding: 0;

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
            border-bottom: none;
        }

        select {
            display: block;
            font-size: 18px;
            background-size: 27px 18px;
            background-position: 97% 23px;
            background-size: 18px 8px;
            max-width: 318px;
            border: none;

            @media only screen and (max-width: 576px) {
                max-width: 100%;
            }
        }
    }
}

.sidebar-col-color-mobile {
    @media only screen and (max-width: 576px) {
        background-color: $white !important;

        .sidebar-menu-wrap {
            margin-top: -30px;
            margin-bottom: 0;
        }
    }
}