/*
* Global Settings throughout the site
*/

body {
    background: $white;
    color: #222222;
    &.error404 {
        background: $gray-200;
    }
    &::after {
        display: block;
        overflow: hidden;
        width: 0;
        height: 0;
        content: url('../../images/enroll-icon.svg') url('../../images/transfer-icon.svg') url('../../images/enroll-icon-hover.svg') url('../../images/transfer-icon-hover.svg');
    }
}

img {
    max-width: 100%;
    height: auto;
}

.bd-black {
    background-color: $black !important;
    color: $white !important;
}

.bd-white {
    background-color: $white !important;
    color: $black !important;
}

a {
    color: $lightblue;
    &:hover {
        color: $lightblue;
        text-decoration: underline;
        text-decoration-color: $red;
        text-decoration-thickness: 3px;
    }
}

.lead {
    font-size: 18px;
}

.clickable-div {
    cursor: pointer;
}

.mobile-display-block {
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
        padding-bottom: 35px;
        border-bottom: 2px solid #E6E9ED;
        margin-bottom: 24px;
    }
}

.mobile-display-none {
    @media only screen and (max-width: 991px) {
        display: none;
    }
}

body.page-standard-inner, body.standard-inner {
    background-color: $secondaryblue;
}

.cost-and-pay-chart {
    background-image: url('../../images/chart.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 230px;
    margin: 50px 0;
    @media only screen and (max-width: 768px) {
        height: 400px;
        background-image: url('../../images/pellissippi-chart.svg');
        background-position: center;
    }
  }

  .d-flex-gutter {
    gap: 50px;
    @media only screen and (max-width: 768px) {
        display: initial !important;
    }
    div {
        flex: 1;
    }
  }

  .border-top-dark {
    border-top: solid 1px #22222280;
  }
  .border-bottom-dark {
    border-bottom: solid 1px #22222280;
  }

 
.container-fluid{
    padding:0 30px;

   @include media-breakpoint-up(xxl) {
    max-width: 1440px;
    padding: 0;
   }
}

.standard-inner-container.container {
    max-width: 1438px;
}

.standard-inner-right-column {
    @media only screen and (min-width: 1438px) {
        padding-left: 100px;
        padding-right: 100px;
        .darkblue-bg.row, .secondaryblue-bg.row, .blue-bg.row {
            margin-left: -100px;
            margin-right: -100px;
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
    }
}
.sidebar-col-color-mobile {
    @media only screen and (min-width: 1438px) {
        padding-right: 50px;
    }
}

.clearfix, .clear-fix {
    clear: both;
}