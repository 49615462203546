.related-posts {
    max-width: 870px;
    hr {
        @media screen and (min-width: 1080px) {
            margin: 0 -30px;
        }
    }
    .inner {
        @media screen and (min-width: 1080px) {
            margin: 0 -30px;
        }
    }
    .feat-title {
        font-size: 38px;
        line-height: 40px;
        font-family: $primary-bold-font;
        color: $defaultblue;
        font-variation-settings: "wght" 700;
        text-align: center;
        padding: 55px 0 30px 0;
    }
    .card {
        background: none;
        border: none;
        @media screen and (max-width: 768px) {
            margin-bottom: 22px;
        }
        .card-body {
            padding: 15px 18px;
            @media screen and (max-width: 768px) {
                padding: 15px 0;
            }
            h5 {
                font-size: 18px;
                margin-bottom: 15px;
                a {
                    color: $off-black;
                    text-decoration: none;
                    font-size: 18px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .card-text {
                margin-bottom: 10px;
            }
            &:hover {
                h5 {
                    a {
                        text-decoration: underline;
                        color: $defaultblue;
                    }
                }
            }
        }
    }
}