
.article {
    max-width: 870px;
    .article-header {
        color: $defaultblue;
        font-size: 55px;
        line-height: 55px;
        font-family: $primary-bold-font;
        font-variation-settings: 'wght' 700;
        margin-bottom: 30px;
        padding-bottom: 0;
        @media screen and (max-width: 759px) {
            line-height: 38px;
        }
    }
    p {
        font-size: 18px;
        line-height: 24px;
        font-family: $secondary-font;
        margin: 0 0 2rem;
    }
    .date-time {
        p {
            border-bottom: 1px solid gray;
            padding: 0 0 20px 0;
            margin-bottom: 20px;
        }
        .category, .date, .author {
            &::after {
                content: "|";
            }
            &:last-child {
                &::after {
                    content: none;
                }
            }
        }
        .author {
            &::after {
                content: "";
            }
        }
    }
    .feat-img {
        img {
            width: 100%;
        }
    }
}

