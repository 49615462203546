//Setup the container for the site
.container { max-width: 1285px;}

//Standard Full widths, etc.
.w-full { width: 100% !important; }  
.w-max-full { max-width: 100% !important; }
.w-auto { width: auto !important; }

//Max widths
.mw-200 { max-width: 200px }
.mw-300 { max-width: 300px }
.mw-400 { max-width: 400px }
.mw-600 { max-width: 600px }
.mw-768 { max-width: 768px }
.mw-800 { max-width: 800px }
.mw-876 { max-width: 876px }
.mw-900 { max-width: 900px }
.mw-1000 { max-width: 1000px }
.mw-1200 { max-width: 1200px }

//Max heights
.mh-200 { max-height: 200px }
.mh-300 { max-height: 300px }
.mh-400 { max-height: 400px }
.mh-600 { max-height: 600px }
.mh-768 { max-height: 768px }
.mh-800 { max-height: 800px }
.mh-1000 { max-height: 1000px }
.mh-1200 { max-height: 1200px }

//Min heights
.mih-100 { min-height:100px; }
.mih-200 { min-height:200px; }
.mih-240 { min-height:240px; }
.mih-300 { min-height:300px; }
.mih-400 { min-height:400px; }
.mih-500 { min-height:500px; }

//Heights 
.h-100 { height:100px; }
.h-200 { height:200px; }
.h-300 { height:300px; }
.h-400 { height:400px; }
.h-500 { height:500px; }
.h-24 { height:24px; }
.h-32 { height:32px; }