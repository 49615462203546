// ======================
// BG Color Classes
// ======================
.bg-primary { background-color: $primary_color !important; }
.bg-secondary { background-color: $secondary_color !important; }
.bg-tertiary { background-color: $tertiary_color !important; }
.bg-white { background-color: $white !important; }
.bg-black { background-color: $black !important; }
.bg-transparent { background-color: transparent !important; }
.blue-bg { background: #004B8E !important; }
.gray-bg, .grey-bg { background: #F4F5F7 !important; }