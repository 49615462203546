/**
* === Alert ===
* A Bootstrap 5 alert box
*/
.alert {
  border-radius: 0 !important;
  margin-bottom:0;
  font-size: rem-calc(15);
  line-height: rem-calc(17);
  font-family: $secondary-font;
  letter-spacing: .013px;
  padding-bottom:10px;
  padding-top:10px;

 
  &.alert-danger {
    background-color: #E2002E;
    color: $white;
    border: solid 1px #E2002E;
    a {
      color: $white;
      padding: 0 2px;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
  &.alert-primary {
    background-color: #00355F;
    color: $white;
    border: solid 1px #00355F;
    a {
      color: $white;
      padding: 0 2px;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
  &.alert-secondary {
    background-color: #E7E8EA;
    color: $off-black;
    border: solid 1px $off-black;
    a {
      color: $black;
      padding: 0 2px;
      &:hover {
        color: $off-black !important;
        text-decoration: none;
      }
    }
  }
  &.alert-success {
    background-color: #337145;
    color: $white;
    border: solid 1px #337145;
    a {
      color: $white;
      padding: 0 2px;
      &:hover {
        color: $off-black;
        text-decoration: none;
      }
    }
  }
  &.alert-info {
    background-color: #FFD350;
    color: $off-black;
    border: solid 1px #FFD350;
    a {
      color: $off-black;
      padding: 0 2px;
      &:hover {
        color: $off-black;
        text-decoration: none;
      }
    }
  }
  &.alert-light {
    background-color: $white;
    color: $off-black;
    border: solid 1px $off-black;
    a {
      color: $off-black;
      padding: 0 2px;
      &:hover {
        color: $off-black;
        text-decoration: none;
      }
    }
  }
  &.alert-dark {
    background-color: #6C7175;
    color: $white;
    border: solid 1px #6C7175;
    a {
      color: $white;
      padding: 0 2px;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
  &.alert-warning {
    background-color: #FFF61A;
    color: $off-black;
    border: solid 1px #FFF61A;
    a {
      color: $defaultblue;
      &:hover {
        color: $defaultblue;
        text-decoration: none;
      }
    }
  }
  &.alert-dismissible{
    padding-right:2rem !important;
    @include media-breakpoint-up(md) {
      padding-right:0px !important;
    }
  }
  &.alert-dismissible .btn-close {
    color: #222;
    opacity: 1;
    background: transparent url('../../images/alertclose.svg') center/1em auto no-repeat;
    padding:0;
    position: absolute;
    right: 0;
    top: 50%;
    font-size: rem-calc(16);
    transform: translate(-50%, -50%);
    @include media-breakpoint-up(md) {
      padding-right:2rem;
      position: absolute;
      top: 50%;
      font-size:rem-calc(14);
      right: 0;
      font-size:13px;
      transform: translate(-50%, -50%);
    }
  }
}
