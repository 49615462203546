.top-bar{
    background-color: $defaultblue;
    padding:7px 0;
    .left-col-inline-links {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-right: 34px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
      a{
        font-size:rem-calc(14);
        line-height: 17px;
        font-family: $secondary-font;
        color:$white;
        text-decoration: underline;
        &:hover{
          transition: 0.5s ease;
          background-color: #222222;
        }
      }
  
      .student-contact{
        ul{
          list-style: none;
  
          li{
            &.has-child {
              > a{
                &:after{
                  display: inline-block;
                  margin-left: 0.255em;
                  content: "+";
                  font-weight: 900;
                }
                &:hover{
                  ul{
                    display: block !important;
                  }
                  
                }
              }
            }
            a.has-child{
              &:after{
                display: inline-block;
                margin-left: 0.255em;
                content: "+";
                font-weight: 900;
              }
              &:hover{
                ul{
                  display: block !important;
                }
                
              }
            }

            ul{
                background-color: $white;
                z-index: 300;
                margin: 0;
                padding: 0 14px;
                min-width: rem-calc(140);
                box-shadow: 0px 3px 6px #000000A8;
                display: none;
                &.js-hideElement{
                  display: none;
                }

                &.js-showElement{
                  display: block;
                  position: absolute;
                }
                li{
                    margin:14px 0px;
                    a{
                        color:#222222;
                        text-decoration: none;
                        &:hover{
                            background-color: transparent;
                            text-decoration: underline;
                        }
                    }
                }
              
            }
  
            &.quick-links{
              margin:0 40px;
            }
            &.search{
              margin-left:rem-calc(42);
              position: relative;
              .has-search{
                width: rem-calc(231);
         
                input{
                  background-color:#00355F;
                  border-radius: 3px;
                  border:1px solid #FFFFFF;
                  font-size:rem-calc(16);
                  line-height: 20px;
                  color:$white;
                  font-family: $secondary-font;
                  padding: 1px 0.75rem;
                  &::placeholder{
                    color:$white;
                  }
  
                  &:focus{
                    z-index: 300;
                    transition: 0.5s ease;
                    color:#222222;
                    background-color: $white;
                    position: absolute;
                    height:53px;
                    top: -13px;
                    border:2px solid #FFD350;
                    width: rem-calc(251);
                    right: 0;
                    &::placeholder{
                      color: transparent;
                    }
                    + {
                      .fa-search{
                        &::before{
                          color:#222222;
                          content: "\f00d";
                          z-index: 900;
                          font-size:rem-calc(22);
                        }
                      }
                    }
                  }
                }
      
          
                .fa-search{
                  position: absolute;
                  z-index: 300;
                  display: block;
                  line-height: 26px;
                  text-align: center;
                  cursor: pointer;
                  right: 20px;
                  color: white;
                  font-size: 13px;
                  top:0;
                 
                }
              }
              label.visually-hidden {
                color: $white;
                background-color: $defaultblue;
              }
            }         
          }
        }
      }
  }