/* STORY BLOCK */
.wf-testimonial-block {
  @include mq( 'phablet', 'max' ) {
    a.btn.btn-link.event-read-more.btn-rt-icon {
      display: block;
    }
  }
  .col-lg-6.position-relative {
    overflow: hidden;
  }
  .col-lg-6.pt-5.mt-5 {
    padding-bottom: 60px;
  }
    img {
      &.position-absolute.bottom-0 {
        @include mq( 'tablet-large' ) {
          position: absolute !important;
        }
        left: 0;
        right: 0;
        @media only screen and (max-width: 1399px) {
          position: absolute !important;
        }

        @include mq( 'tablet-medium', 'max' ) {
          position: initial !important;
        }
      }
    }
    h1.text-blue.display-5 {
      font-size: 60px !important;
      @include mq('phablet', 'max') {
        font-size: 29px !important;
      }
    }

    .page-header-vrc.text-uppercase {
      @include mq('phablet', 'max') {
        font-size: 20px !important;
      }
    }

    p.font-sofia.text-blue.fs-5 {
      font-size: 18px !important;
    }
    .btn {
      display: initial !important;
      @include mq( 'tablet-medium', 'max' ) {
        display: block !important;
        width: max-content !important;
        margin: 15px 0;
      }
    }
  }

  .testimonial-wrapper {
    background-color: #F4F5F7;
    padding: 72px 138px;
    text-align: center;
    @include mq( 'tablet-medium', 'max' ) {
      padding: 30px;
    }
    .testimonial-excerpt {
      font-size: 24px;
      font-family: $secondary-font;
      line-height: 36px;
    }
    .testimonial-name {
      font-family: $primary-bold-font;
      font-size: 18px;
    }
    .testimonial-title {
      font-family: $secondary-font;
      font-size: 14px;
    }
  }

  .testimonial-fixed-width {
    max-width: 524px;
    margin: auto;
    @media only screen and (max-width: 992px) {
      max-width: 100%;
    }
    .testimonial-wrapper {
      padding: 0 0 72px;
    }
  }