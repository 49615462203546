.carousel{
  @include media-breakpoint-down(sm){
    margin-bottom: 10%;
  }
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    width: 50px;
    height: 50px;
    background-size: 50%;
    background-color: rgba(0,0,0,.8);
    border-radius: 50%;
}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
  color: $tertiary_color !important;
  text-decoration: none !important;
  background-color: transparent;
  border-radius: 53px;
  width: 53px;
  height: 53px;
  top: 50%;
  margin-top: -53px;

  @include media-breakpoint-down(sm){
    bottom: -18px;
    top: inherit;
    margin-top: 0;
  }

  &:hover, &:focus {
    color:$tertiary_color;
    background-color: $shadow-gray;
    opacity: 1;
  }
  .fas {
    font-size: 28px;
    color: inherit;
  }
}
.carousel-indicators {
  @include media-breakpoint-down(md){
    bottom:-15px;
    z-index: 2;
  }
}
.carousel-indicators li {
    width: 15px;
    height: 15px;
    background-color: $shadow-gray;
    opacity: 1;
    border: solid 1px $tertiary_color;
    border-radius: 50%;
      @include media-breakpoint-down(sm){
        margin-left: 6px;
        margin-right: 6px;
      }
}

.carousel-indicators li.active {
    background-color: $secondary_color;
    border: solid 1px $tertiary_color;
}


.carousel-padding .carousel-item {
  padding: 0 14% 9%;

  @include media-breakpoint-down(sm) {
    padding: 0 0 14%;
  }
}

.slider.slick-center-mode {
  .slick-slide {
    opacity: 0.6;
    padding: 15px;
  }
  .slick-slide {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    .card-body {
      display: none;
    }
  }
  .slick-slide.slick-current {
    opacity: 1;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    z-index: 10;
    .card-body {
      display: block;
      padding: 0 0 40px 0;
      h6 {
        margin: 6px 0 0 0;
      }
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}


.play-pause{
  display: inline-block;
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: 16;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 23%;
  list-style: none;
}
.play-pause:hover svg{
  fill: $secondary_color;
}

.play-pause .play{
  display: none;
}

.play-pause.pause .play{
  display: inline-block;
}

.play-pause.pause .pause{
  display: none;
}

.play-pause svg{
  width: 15px;
  fill: $tertiary_color;
}

.slick {
  &.fade-slide {
    .slick-prev {
      left: 0;
      z-index: 99;
      height: 55px;
      width: 55px;
      top: 100%;
      transform: translate(0,0%);
      .slick-prev-icon {
        font-family: $fontawesome !important;
        background: $white;
        width: 55px;
        height: 55px;
        line-height: 55px;
        padding: 0;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
        opacity: 1;
        &::before {
          content: "\f053" !important;
        }
      }
      &:focus {
        top: calc(50% - 0px);
        .slick-prev-icon {
          margin-left: 0;
        }
      }
      &:hover, &:focus {
        top: 100%;
        transform: translate(0,0%);
        .slick-prev-icon {
          background: $defaultblue !important;
          outline: solid 2px $red;
          &::before {
            color: $red;
          }
        }
      }
    }
    .slick-next {
      right: 0;
      z-index: 99;
      height: 55px;
      width: 55px;
      top: 100%;
      transform: translate(0,0%);
      .slick-next-icon {
        font-family: $fontawesome !important;
        font-family: $fontawesome !important;
        background: $white;
        width: 55px;
        height: 55px;
        line-height: 55px;
        padding: 0;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
        opacity: 1;
        &::before {
          content: "\f054" !important;
        }
      }
      &:focus {
        top: calc(50% - 0px);
        .slick-next-icon {
          margin-left: 0;
        }
      }
      &:hover, &:focus {
        top: 100%;
        transform: translate(0,0%);
        .slick-next-icon {
          background: $defaultblue !important;
          outline: solid 2px $red;
          &::before {
            color: $red;
          }
        }
      }
    }

    .slick-next:focus .slick-next-icon, .slick-next:focus .slick-prev-icon, .slick-prev:focus .slick-next-icon, .slick-prev:focus .slick-prev-icon {
      font-size: 24px;
    }

    .slick-dots {
      li {
        &.slick-active button .slick-dot-icon {
          color: $off-black;
        }
        button {
          height: 16px;
          width: 16px;
          margin-top: -3px;
          margin-left: -1px;
          border: solid 1px $off-black;
          border-radius: 50%;
          &:hover, &:focus, &:focus-within {
            .slick-dot-icon {
              color: $off-black;
              &::before {
                color: transparent;
              }
            }
          }
          .slick-dot-icon {
            color: $white;
            &:before {
              margin-top: -3px;
              margin-left: -2px;
              font-size: 18px;
              color: transparent;
            }
          }
          &:hover, &:focus, &:focus-within {
            background-color: #222222;
          }
        }
        &.slick-active {
          button {
            background-color: #222222;
          }
        }
      }
    }
    /*--- dark background --- */
    
    &.fade-slide-dark {
      .slick-dots {
        li {
          &.slick-active button .slick-dot-icon {
            color: $white;
          }
          button {
            border: solid 1px $white;
            &:hover, &:focus, &:focus-within {
              .slick-dot-icon {
                color: $white;
                &::before {
                  color: $white;
                }
              }
            }
            .slick-dot-icon {
              color: transparent;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}