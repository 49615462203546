/**
* === Accordion ===
*/
.wf-accordion {
  color: #0c1333;
  background-color: #fff;
  display: block;

  .title {
    color: #042D61;
    font-family: $primary-font;
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    font-variation-settings: 'wght' 700 !important;
  }

  .expand-all-btn {
    padding: 12px 0;
    text-align: right;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    color: #222222;
    font-family: Arial, Helvetica, sans-serif;
    &:hover, &:focus {
      text-decoration: underline;
    }
    &::before {
        content: "Expand/Close All";
        //font-family: "Font Awesome 6 Free";
        margin-left: 5px;
    }
    &.close-all {
        &::before {
            content: "Close All";
        }
        &::before {
            content: "\f068";
            font-family: "Font Awesome 6 Free";
            margin-left: 14px;
        }
    }
  }

  .tabs-tab-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
  }
  .subline {
    display: block;
    width: 100%;
  }
  .tabs-tab-list li {
      margin: 0;
  }
    
  .tabs-trigger {
    border-bottom: none;
    color: #666;
    display: none;
    font-weight: normal;
    margin: 0 5px 0 1px;
    padding: 15px 20px;
    text-decoration: none;
    &:active {
      outline: none;
    }
    &:hover {
      border-bottom: none;
      color: #000;
    }
    &.is-selected, 
    &.is-selected:hover, 
    &.is-selected:focus {
      border-bottom: 2px solid #000;
      color: #000;
    }
  }
    
  .tabs-panel {
    display: block;
    margin: 0 0 7px;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
    &.is-open {
      outline: solid 3px $defaultblue;
    }
    &:not(:first-of-type) {
      border-top: none;
    }
    &:not(:last-child) {
      // border-bottom: 1px solid #eee;
      border-bottom: none;
    }
    .content {
      margin-top: 10px;
      padding: 20px;
    }
    &.is-hidden .content {
      display: none;
    }
    &:active, 
    &:focus {
      outline: none;
    }
  }

  .is-initialized.tabs-allowed .tabs-panel {
      display: inherit;
  }
    
  .accordion-trigger {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    cursor: pointer;
    padding: 15px;
    background: $white;
    color: $off-black;
    font-size: 18px;
    line-height: 24px; 
    font-weight: 300;
    margin: 0;
    font-family: $primary-bold-font;
    &::before {
        content: "\2b";
        font-family: "Font Awesome 6 Free";
        margin-right: 15px;
    }
    &:hover {
      outline: solid 3px $defaultblue;
      color:#004B8E;
      span {
        text-decoration: underline;
      }
      &::before {
        text-decoration: none;
      }
    }
    &.is-open{
      &::before {
        content: "\f068";
        font-family: "Font Awesome 6 Free";
        margin-right: 15px;
      }
    }
  }

  .is-open {
    background-color: $white;
    .accordion-trigger {
      color: $off-black;
      border-radius: 0;
      &::before {
        content: "\f068";
        font-family: "Font Awesome 6 Free";
      }
      &:hover {
        outline: none;
        span {
          text-decoration: none;
        }
      }
    }
  }
    
  @media (min-width: 40em) {
      .tabs-allowed .accordion-trigger {
        display: none;
      }
      .tabs-allowed .tabs-trigger {
        display: block;
      }
      .tabs-allowed .tabs-panel {
        display: none;
      }
      .tabs-allowed .tabs-panel.is-hidden {
        display: none;
      }
      .tabs-allowed .tabs-panel {
        border-top: 2px solid #eee;
      }
      .tabs-allowed .tabs-tab-list {
        margin-bottom: -2px;
      }
  }
    
  .accordion-trigger-icon {
    pointer-events: none;
    float: right;
    width: 22px;
    height: 22px;
    svg {
      margin: 0;
    }
  }
    
  [aria-expanded="true"] .vert {
      display: none;
  }
    
  .label--open, 
  .label--close {
      display: block;
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
  }
    
  .label--close {
      display: none;
  }
  
  .is-open .label--open {
      display: none;
  }
  
  .is-open .label--close {
      display: block;
  }

  &.dark-accordion {
    background-color: #3264A6;
    .title {
      color: white;
      font-family: 'montserrat-bold';
      font-size: 24px;
      line-height: 26px;
      font-weight: bold;
    }
    .accordion-trigger {
      color: white;
      background: $defaultblue;
    }
    .is-open {
      background-color: #E4E4E4;
    }
    .expand-all-btn {
      color: white;
    }
  }
}

//Bootstrap Accordion
.accordion {
  .accordion-item {
    border: none;
    margin-bottom: 7px;
    .accordion-header {
      .accordion-button {
        padding: 15px;
        font-size: 18px;
        line-height: 24px;
        color: $white;
        background-color: $defaultblue;
        &:hover, &:focus {
          outline: none;
          text-decoration: none;
        }
        &.collapsed {
          background: #E6E9ED;
          color: $defaultblue;
          &:hover, &:focus {
            outline: solid 3px #001641;
            text-decoration: underline;
            &::before {
              text-decoration: inherit;
              text-decoration-color: #e9ecef !important;
            }
          }
        }
        &::before {
          content: "\2b";
          font-family: "Font Awesome 6 Free";
          background-image: none;
        }
        &:not(.collapsed)::before {
          background-image: none;
          content: "\f068";
          font-family: "Font Awesome 6 Free";
        }

      }
    }
    .accordion-collapse {
      background-color: #E6E9ED;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &.show {

      }
      .accordion-body {
        
      }
    }
    &:first-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .accordion-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &:last-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0;
      .accordion-button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.faq-section {
  .wf-accordion .accordion-trigger {
    font-size: 32px;
    line-height: 32px;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
    }
  }
}