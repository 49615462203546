.pattern-gradient-bg {
    background-image: url('../../images/pattern-default.svg');/* fallback */
        background-image: linear-gradient(to top, #E6E9ED, transparent), url('../../images/pattern-default.svg');
        background-size: cover, 600px 600px;
        @media only screen and (max-width: 768px) {
            padding-bottom: 70px;
        }
        .skew-container-max-width {
            max-width: 1433px;
            margin: auto;
        }
}

.pattern-gradient-bg-secondary {

    background-image: url('../../images/pattern-default.svg');/* fallback */
        background-image: linear-gradient(to top, $white, transparent), url('../../images/pattern-default.svg');
        background-size: cover, 600px 600px;
        @media only screen and (max-width: 768px) {
            padding-bottom: 70px;
        }
}

.pattern-white-bg {
        background-image: url('../../images/pattern-default.svg');
        background-size: 600px 600px;
        @media only screen and (max-width: 768px) {
            padding-bottom: 70px;
        }
}

.parallax-bg-image {
    background-image: url('../../images/pscc_img_10.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 289px 16px;
    background-position: 50% 50%;
    background-attachment: fixed; 
    overflow: hidden;
    margin-top: -1px;
    &.did-you-know-section {
        .col-lg-4 {
            @media only screen and (max-width: 992px) {
                margin-bottom: 1.5rem;
            }
        }
        .content-lft {
            min-height: 100%;
            padding: 30px 40px;
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 160px 16px;
        background-attachment: scroll;
    }

    
      

      
      #triangle-holder-top-parralax-primary {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        .triangle-down {
            box-sizing: content-box;
            width: 50%;
            height: 150px;
            padding-left: 50%;
            padding-top: 0;
            overflow: hidden;
            z-index: 1;
            div {
                width: 0;
                height: 0;
                margin-left: -1000px;
                margin-top: 0;
                border-left: 1000px solid transparent;
                border-right: 1000px solid transparent;
                border-top: 150px solid $defaultblue;
                @media only screen and (max-width: 992px) {
                  margin-left: -600px;
                  border-left: 600px solid transparent;
                  border-right: 600px solid transparent;
                }
                @media only screen and (max-width: 768px) {
                  margin-left: -400px;
                  border-left: 400px solid transparent;
                  border-right: 400px solid transparent;
                }
              }
          }
      }

      #triangle-holder-top-parralax-secondary {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        z-index: 1;

        .triangle-down {
            box-sizing: content-box;
            width: 50%;
            height: 150px;
            padding-left: 50%;
            padding-top: 0;
            overflow: hidden;
            z-index: 1;
            div {
                width: 0;
                height: 0;
                margin-left: -1000px;
                margin-top: 0;
                border-left: 1000px solid transparent;
                border-right: 1000px solid transparent;
                border-top: 150px solid $white;
                @media only screen and (max-width: 992px) {
                  margin-left: -600px;
                  border-left: 600px solid transparent;
                  border-right: 600px solid transparent;
                }
                @media only screen and (max-width: 768px) {
                  margin-left: -400px;
                  border-left: 400px solid transparent;
                  border-right: 400px solid transparent;
                }
              }
          }
      }
}

.darkblue-bg {
    background-color: $defaultblue;
}
.white-bg {
    background-color: $white;
}
.secondaryblue-bg {
    background-color: $secondaryblue;
}
.turquoise-bg {
    background-color: $turquoise;
}
.bg-mobile-bleed {
    @media only screen and (max-width: 992px) {
        margin-left: -12px;
        margin-right: -12px;
        &.p-4 {
            padding: 30px 15px !important;
        }
    }
}
.static-bg-image-offset-wrapper {
    @media only screen and (min-width: 769px) {
        position: relative;
        margin-bottom: -7px;
    }
    .static-bg-image {
        background-image: url('../../images/pscc_img_7.png');
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 861px;
        background-position: 50% 50%;
        @media only screen and (max-width: 768px) {
            min-height: 367px;
        }
    }
    .static-bg-image-parallax {
      background-image: url('../../images/pscc_img_7.png');
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 600px;
      background-position: 50% 50%;
      background-attachment: fixed;
      @media only screen and (max-width: 768px) {
          min-height: 367px;
          background-attachment: inherit;
      }
    }
    .static-bg-offset-fake-footer {
        @media only screen and (min-width: 769px) {
            background: $white;
            border-top: solid 7px #FFD350;
        }
        .offset-container {
            
            .cta-offset-wrapper {
              margin-top: -100px;
                .cta-banner.cta-banner-sidebar {
                    margin-bottom: 0;
                }
            }
        }
    }
    .static-bg-offset-fake-header {
      @media only screen and (min-width: 769px) {
          background: $white;
      }
      .offset-container {
          
          .cta-offset-wrapper {
            margin-bottom: -100px;
            position: relative;
            z-index: 1;
              .cta-banner.cta-banner-sidebar {
                  margin-bottom: 0;
              }
          }
      }
  }
    &.static-bg-image-offset-footer {
      .static-bg-offset-fake-footer {
        .container.offset-container {
          @media only screen and (max-width: 768px) {
              padding: 0;
              .cta-offset-wrapper {
                margin-top: 0;
              }
          }
        }
      }
  }
    &.static-bg-image-offset-white {
        @media only screen and (min-width: 769px) {
            position: relative;
            margin-bottom: 0;
        }
        @media only screen and (max-width: 768px) {
          .container.offset-container {
            padding-bottom: 15px;
          }
        }
        .static-bg-offset-fake-footer {
            @media only screen and (min-width: 769px) {
                background: $white;
                border-top: solid 7px $white;
                padding-bottom: 30px;
            }
        }
    }

    &.static-bg-image-offset-grey {
        @media only screen and (min-width: 769px) {
            position: relative;
            margin-bottom: 0;
        }
        @media only screen and (max-width: 768px) {
          .container.offset-container {
            padding-bottom: 15px;
          }
        }
        .static-bg-offset-fake-footer {
          background: #F4F5F7;
          border-top: solid 7px #F4F5F7;
        }
    }
    .blue-bg {
      @media only screen and (min-width: 769px) {
        padding: 60px 0;
      }
      @media only screen and (max-width: 768px) {
        padding: 60px 0 200px;
      }
    }
}

.two-col-masked {
    display: flex;
    @media only screen and (max-width: 992px) {
        display: block;
    }
    .masked-column,
    .content-column {
      flex: 1;
    }
  
    .masked-column {
      background: $white;
      position: relative;
      overflow: hidden;
      padding: 100px 100px 100px 15px;
      @media only screen and (max-width: 992px) {
        padding: 100px 15px;
      }
  
      &:before,
      &:after {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        display: block;
        width: 60%;
        height: 50%;
        border: 100px solid $defaultblue;
        z-index: 1;
        @media only screen and (max-width: 992px) {
            display: none;
        }
      }
  
      &:before {
        border-top: 500px solid transparent;
        border-bottom: 0;
        bottom: 0;
        border-left: 0;
        right: 0;
      }
  
      &:after {
        right: 0;
        border-bottom: 500px solid transparent;
        border-top: 0;
        border-left: 0;
        top: 0;
      }
    }

    .masked-column {
      background: $white;
      position: relative;
      overflow: hidden;
      padding: 100px 100px 100px 15px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      @media only screen and (max-width: 992px) {
        padding: 100px 15px;
      }
  
      &:before,
      &:after {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        display: block;
        width: 60%;
        height: 50%;
        border: 100px solid $defaultblue;
        z-index: 1;
        @media only screen and (max-width: 992px) {
            display: none;
        }
      }
  
      &:before {
        border-top: 500px solid transparent;
        border-bottom: 0;
        bottom: 0;
        border-left: 0;
        right: -20px;
      }
  
      &:after {
        right: -20px;
        border-bottom: 500px solid transparent;
        border-top: 0;
        border-left: 0;
        top: 0;
      }
      .masked-column-nested {
    
        &:before,
        &:after {
          box-sizing: border-box;
          content: " ";
          position: absolute;
          display: block;
          width: 60%;
          height: 50%;
          border: 100px solid #F4F5F7;
          z-index: 2;
          @media only screen and (max-width: 992px) {
              display: none;
          }
        }
    
        &:before {
          border-top: 500px solid transparent;
          border-bottom: 0;
          top: 40%;
          border-left: 0;
          right: 0px;
        }
    
        &:after {
          right: 0px;
          border-bottom: 500px solid transparent;
          border-top: 0;
          border-left: 0;
          bottom: 40%;
        }
      }
    }
  
    .content-column {
      background: $defaultblue;
      padding: 0 15px;
    }
  }

  .two-col-masked-inverted {
    display: flex;
    max-width: 1440px;
    margin: auto;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    .masked-column,
    .content-column {
      flex: 1;
      @media only screen and (max-width: 992px) {
        flex: 0 0 100%;
      }
    }
    .masked-column {
      @media only screen and (max-width: 992px) {
        order: 1;
      }
    }
    .content-column {
      @media only screen and (max-width: 992px) {
        order: 2;
      }
    }
  
    .masked-column {
      background: $white;
      position: relative;
      overflow: hidden;
      padding: 100px 15px 100px 100px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      @media only screen and (max-width: 992px) {
        padding: 100px 15px;
      }
  
      &:before,
      &:after {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        display: block;
        width: 60%;
        height: 50%;
        border: 100px solid $defaultblue;
        @media only screen and (max-width: 992px) {
            left: -70px !important;
        }
      }
  
      &:before {
        border-top: 500px solid transparent;
        border-bottom: 0;
        bottom: 0%;
        border-right: 0;
        left: 0;
      }
  
      &:after {
        left: 0;
        border-bottom: 500px solid transparent;
        border-top: 0;
        border-right: 0;
        top: 0;
      }

      .masked-column-nested {
        &:before,
        &:after {
          box-sizing: border-box;
          content: " ";
          position: absolute;
          display: block;
          width: 60%;
          height: 50%;
          border: 100px solid #F4F5F7;
          z-index: 2;
          @media only screen and (max-width: 992px) {
              display: none;
          }
        }
    
        &:before {
          border-top: 500px solid transparent;
          border-bottom: 0;
          bottom: 0;
          border-right: 0;
          left: 0px;
        }
    
        &:after {
          left: 0px;
          border-bottom: 500px solid transparent;
          border-top: 0;
          border-right: 0;
          top: 0;
        }
      }
    }
  
    .content-column {
      background: $defaultblue;
      padding: 15px;
      .content-column-mobile-hidden {
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
    }

    .hidden-column {
      padding: 15px;
      display: none;
      @media only screen and (max-width: 992px) {
        display: initial;
        h1, .h1 {
          line-height: 40px;
        }
      }
    }
  }

  .white-bg-box-shadow {
    background-color: $white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
      p {
        margin-bottom: 5px;
      }
      &.program-info-block {
        margin: 40px 0 20px;
      }
  }

  .box-shadow {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  }
  
  .static-bg-image-offset-wrapper {
    .blue-bg {
      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ol, ul {
        color: $white;
      }
      ol.ordered-list-default, ul.unordered-list-default {
        color: $white;
        li::before {
          background: $white;
        }
        ul li::before {
          background: $defaultblue;
          border: solid 2px $white;
      }
      }
    }
  }

  .blue-bg-section {
    &.blue-bg {
      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ol, ul {
        color: $white;
      }
      ol.ordered-list-default, ul.unordered-list-default {
        color: $white;
        li::before {
          background: $white;
        }
        ul li::before {
          background: $defaultblue;
          border: solid 2px $white;
      }
      }
    }
  }