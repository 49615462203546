.table {
  border-left: 1px solid #BABABA;
  border-right: 1px solid #BABABA;
  th {
    color: $white;
    background-color: $defaultblue;
    border-color: transparent;
    font-weight: normal;
    padding: 7px !important;
    text-align: center;
    font-family: $primary-bold-font !important;
    font-size: 18px !important;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  tr {

    &:nth-child(odd) {
      background: white;
      border-bottom: 1px solid #BABABA;
    }
    &:nth-child(even) {
      background: #E4E4E4;
    }
    td {
      border-right: 1px solid #BABABA;
      text-align: center;
      &:last-child {
        border-right: none;
      }
    }
    @media only screen and (min-width: 640px) {
      &:hover {
        background-color: $lightblue;
        p {
          color: $white;
        }
        a {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }
  &.table-scroll-mobile {
    @media only screen and (max-width: 640px) {
      max-width: 100%;
      overflow-x: auto;
      display: block;
    }
  }
}

// Sortable Tables
.table-sortable > thead > tr > th {
  cursor: pointer;
  position: relative;
}

.table-sortable > thead > tr > th:after,
.table-sortable > thead > tr > th:after,
.table-sortable > thead > tr > th:after {
  content: ' ';
  position: absolute;
  height: 0;
  width: 0;
  right: 10px;
  top: 16px;
}

.table-sortable > thead > tr > th:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ccc;
  border-bottom: 0px solid transparent;
}

.table-sortable > thead > tr > th:hover:after {
  border-top: 5px solid #888;
}

.table-sortable > thead > tr > th.asc:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 0px solid transparent;
  border-bottom: 5px solid #333;
}
.table-sortable > thead > tr > th.asc:hover:after {
  border-bottom: 5px solid #888;
}

.table-sortable > thead > tr > th.desc:after {    
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  border-bottom: 5px solid transparent;
}
th.header {
  padding: 13px 10px !important;
  font-family: auto !important;
  font-weight: bold !important;
  FONT-SIZE: 17PX!important;
}