// =============
// Imagehover.css overrides
// =============

$ih-image-bg:             #2266a5 !default;
$ih-figcaption-bg:        #135796 !default;
$ih-figcaption-padding:   30px !default;
$ih-text-color:           #ffffff !default;
$ih-border-color:         #ffffff !default;
$ih-border-margin:        0px !default;
$ih-border-width:         0px !default;
$ih-transition-duration:  0.35s !default;
$ih-shift-distance:       10px !default;

// =============
// Color Palette
// =============

$primary_color:             #3264A6;
$secondary_color:           #FFCE00;
$tertiary_color:            #042D61;

// =============
// descriptive colors
// =============

$white:                     #ffffff;
$regent-grey:               #808c99;
$pale-sky:                  #6b717f;
$midnight-blue:             #036;
$black:                     #000;
$off-black:                 #222222;
$gray:                      #E4E4E4;
$grayer:                    #E5E5E5;
$light-gray:                #F5F5F5;
$dark-gray:                 #676767;
$darker-gray:               #3B3B3B;
$shadow-gray:               #BFBFBF;
$hover-bg-gray:             #f8f9fa;

// =============
// Gutenberg color options
// -- see editor-color-palette in functions.php
// =============

$defaultblue:               #004B8E;
$primaryblue:               #001641;
$secondaryblue:             #F4F5F7;
$red:                       #FFD350;
$lightblue:                 #30609A;
$turquoise:                 #4E9FAE;


$wp_colors: (
    'black' :               $black,
    'white' :               $white,
	'cyan-bluish-gray' :    #abb8c3,
    'pale-pink' :           #f78da7,
	'vivid-red' :           #cf2e2e,
	'luminous-vivid-orange':#ff6900,
	'luminous-vivid-amber' :#fcb900,
    'light-green-cyan' :    #7bdcb5,
	'vivid-green-cyan' :    #01d083,
    'pale-cyan-blue' :      #8ed1fc,
	'vivid-cyan-blue' :     #0693e3,
    'vivid-purple' :        #9b51e0,
);

$wp_color_hover: (
    'black' :               $black,
    'white' :               $white,
	'cyan-bluish-gray' :    #abb8c3,
    'pale-pink' :           #f78da7,
	'vivid-red' :           #cf2e2e,
	'luminous-vivid-orange':#ff6900,
	'luminous-vivid-amber': #fcb900,
    'light-green-cyan' :    #7bdcb5,
	'vivid-green-cyan' :    #01d083,
    'pale-cyan-blue' :      #8ed1fc,
	'vivid-cyan-blue' :     #0693e3,
    'vivid-purple' :        #9b51e0,
);