
$arrow-size: 1em;
$arrow-border: 0.2em;
.card-group {
    gap: 1.5rem;
    .card.card-ws {
        border: none;
        padding: 26px;
        border-radius: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
        @media only screen and (max-width: 768px) {
            flex: inherit;
            width: 100%;
        }
        .card-image {
            margin: -27px -27px 15px;
        }
        .card-body {
            padding: 0;
            .card-icon-link-enroll, .card-icon-link-transfer, .card-icon-link-support, .card-icon-link-community {
                height: 67px;
                width: 61px;
                text-indent: -9999px;
                overflow: hidden;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
                color: $defaultblue;
            }
            .card-icon-link-enroll {
                background-image: url(../../images/handshake.svg);
            }
            .card-icon-link-transfer {
                background-image: url(../../images/flight.svg);
            }
            .card-icon-link-support {
                background-image: url(../../images/volunteer_activism.svg);
            }
            .card-icon-link-community {
                background-image: url(../../images/group.svg);
            }
            .card-title a {
                font-size: 24px;
                color: $off-black;
                text-decoration: none;
            }
            p {
                font-size: 16px;
                color: $off-black;
            }
            .card-link {
                font-size: 18px;
                color: $off-black;
                text-decoration: none;
                &::after {
                    display: inline-block;
                    margin-left: 8px;
                    vertical-align: inherit;
                    font-family: $fontawesome;
                    content: "\f105";
                    line-height: 0;
                }
            }
        }
        &.card-default {
            &:hover, &:focus-within {
                outline: solid 3px $defaultblue;
                .card-title a, .card-link {
                    text-decoration: underline;
                }
            }
            &:hover {
                .card-title a, .card-link {
                    text-decoration: underline;
                }
            }
        }
        &.card-primary {
            background-color: $white;
            &:hover, &:focus-within {
                outline: solid 3px $red;
                .card-title a, .card-link {
                    text-decoration: underline;
                }
            }
        }
        &.card-secondary {
            background-color: $white;
            padding: 10px;
            &:hover, &:focus-within {
                outline: solid 3px $red;
                .card-title a, .card-link {
                    text-decoration: underline;
                }
                .card-link {
                    
                    & {
                      &:before {
                        transform:  translate(3.3em, -($arrow-size / 2)) rotate(45deg);
                      }
                      &:after {
                        transform:  translate(3.3em, -($arrow-border / 2));
                        width: 4.3em;
                      }
                    }
                }
                .card-icon-link-enroll, .card-icon-link-transfer, .card-icon-link-support, .card-icon-link-community {
                    height: 67px;
                    width: 61px;
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    color: $defaultblue;
                    margin-bottom: 21px;
                }
                .card-icon-link-enroll {
                    background-image: url(../../images/handshake.svg);
                }
                .card-icon-link-transfer {
                    background-image: url(../../images/flight.svg);
                }
                .card-icon-link-support {
                    background-image: url(../../images/volunteer_activism.svg);
                }
                .card-icon-link-community {
                    background-image: url(../../images/group.svg);
                }
            }
            .card-link {
                position: relative;
                overflow: hidden;
                font-size: 12px;
                padding-left: 30px;
                span {
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                }
                &:after, &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  transition: all 0.5s ease-in-out;
                }
                &:before {
                  top: 50%;
                  right: $arrow-size;
                  width: $arrow-size;
                  height: $arrow-size;
                  transform-origin: 50% 50%;
                  transform:  translate(0, -($arrow-size / 2)) rotate(45deg);
                  box-shadow: inset -#{$arrow-border} $arrow-border 0 0 $defaultblue;
                  border-radius: 0 $arrow-size*0.15 0 0;
                }
                
                &:after {
                  top: 50%;
                  right: 1em;
                  width: $arrow-size * 1.3;
                  height: $arrow-border;
                  transform:  translate(0, -($arrow-border / 2));
                  background-color: $defaultblue;
                }
                &:hover {
                  &:before {
                    transform:  translate(3.3em, -($arrow-size / 2)) rotate(45deg);
                  }
                  &:after {
                    transform:  translate(3.3em, -($arrow-border / 2));
                    width: 4.3em;
                  }
                }
            }
        }
    }
    &.card-group-two-columns {
        @media only screen and (min-width: 576px) {
            flex-wrap: wrap;
                .card {
                    flex: 1 1 48%;
                    max-width: 48%;
                }
        }
    }
}

.did-you-know-section {
    .card-group {
        .card-icon-link-enroll, .card-icon-link-transfer, .card-icon-link-support, .card-icon-link-community {
            height: 50px !important;
            width: 50px !important;
            margin-bottom: 0 !important;
        }
        .card-title a {
            font-size: 18px !important;
        }
    }
}

ul.our-campuses-cards-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 18px;
    @media only screen and (max-width: 768px) {
        display: block;
    }
    .our-campuses-card {
        cursor: pointer;
        flex: 1;
        background: $white;
        @media only screen and (max-width: 768px) {
            margin-bottom: 13px;
        }
        .card-image {
            overflow: hidden;
            height: 163px;
            @media only screen and (max-width: 768px) {
                display: none;
            }
            img {
                width: 100%;
            }
        }
        .card-body {
            padding: 17px;
            .card-title {
                a {
                    font-size: 18px;
                    font-family: $primary-bold-font;
                    color: $off-black;
                    margin-bottom: 11px;
                    text-decoration: none;
                }
            }
            .card-content {
                font-size: 14px;
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
        &:hover, &:focus-within {
            .card-image {
                img {
        
                }
            }
            .card-body {
                .card-title {
                    a {
                        text-decoration: underline;
                    }
                }
                .card-content {
        
                }
            }
        }
    }
}