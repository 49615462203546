
.breadcrumbs {
    color: $off-black;
    font-size: 14px;
    line-height: 17px;
    ol {
        margin-top: 15px;
        li {
            line-height: 25px;
            &:first-child {
                a {
                    overflow: hidden;
                    text-indent: -9999px;
                    display: inline-block;
                    width: 27px;
                    &::after {
                        display: inline-block;
                        margin-left: 8px;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f015";
                        line-height: 0;
                        text-indent: 9956px;
                        color: #30609A;
                        display: inline-block;
                        font-size: 19px;
                    }
                }
            }
        }
    }
    a {
        color: $defaultblue;
        &:hover {
            text-decoration: none;
        }
    }
    .breadcrumb-item.active {
        color: $off-black;
        padding-left: 0;
        font-family: $secondary-font;
    }
    li:last-child {
        color: $off-black;
        padding-left: 0;
        font-family: $secondary-font;
    }
    .breadcrumb-item {
        &::before {
            display: inline-block;
            margin-left: 8px;
            vertical-align: inherit;
            font-family: "Font Awesome 6 Free";
            content: "\f104";
            color: #30609A;
            display: inline-block;
            font-size: 14px;
        }
        &:first-child {
            &::before {
                display: none;
            }
        }
    }
}

.dark-bg-breadcrumbs {
    background: #3264A6;
    color: $white;
    a {
        color: $white;
    }
    .breadcrumb-item.active {
        color: $white;
    }
    ol:last-child {
        color: $white;
    }
}

.two-col-masked, .two-col-masked-inverted {
    .content-column {
        .breadcrumbs {
            color: $white;
            .breadcrumb-item {
                &.active {
                    color: $white;
                }
                a {
                    color: $white;
                    &::after {
                        color: $white;
                    }
                }
                &::before {
                    color: $white;
                }
            }
        }
    }
}

.blue-bg {
    .breadcrumbs {
        color: $white;
        .breadcrumb-item {
            &.active {
                color: $white;
            }
            a {
                color: $white;
                &::after {
                    color: $white;
                }
            }
            &::before {
                color: $white;
            }
        }
        li:last-child {
            color: $white;
            a {
                color: $white;
            }
        }
    }
}