// =============
// Custom Font selections - Use these to select Primary, secondary fonts
// =============
$fontawesome: "Font Awesome 6 Free";
$primary-font: "Oswald";
$primary-bold-font: "Oswald";
$secondary-font: "Lato";
$secondary-bold-font: "Lato";
$secondary-black-font: "Lato";
$tertiary-font: "Lato";
$tertiary-bold-font: "Lato";

// ======================
// Fonts
// ======================
@include fontface-include($primary-font, "Oswald/Oswald-Bold", 'normal', '400');
@include fontface-include($primary-bold-font, "Oswald/Oswald-Bold", 'bold', '700');
@include fontface-include($secondary-font, "Lato/Lato-Regular", 'normal', '400');
@include fontface-include($secondary-bold-font, "Lato/Lato-Bold", 'normal', '700');
@include fontface-include($secondary-black-font, "Lato/Lato-Black", 'normal', '900');

// ======================
// Icon Libraries
// ======================

$fa-font-path: "/assets/fonts/";

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';