.gallery-wrapper {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  li {
    flex: 1 0 29%;
    margin: 5px;
    @media only screen and (max-width: 600px) {
      flex: 1 0 46%;
    }
    a.gallery-img-link {
      .gallery-img-wrapper {
        overflow: hidden;
        margin-bottom: 6px;
        img {
          transition: transform 0.5s ease;
          max-width: inherit;
          width: 100%;
          vertical-align: middle;
          border: solid 1px $off-black;
        }
      }
    }
  }
  &.gallery-wrapper-secondary {
    gap: 0;
    li {
      flex: 1 0 17%;
      @media only screen and (max-width: 996px) {
        flex: 1 0 31%;
        max-width: 31%;
      }
    }
  }
}