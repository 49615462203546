.header {
  position: relative;
  z-index: 999;

  &--main {
    box-shadow: 0px 3px 6px #000000A8;
    background-color: $white;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    transform: translateY(0);

    &.fixed {
      position: fixed;
      width: 100%;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      transition: all 0.5s;
      bottom: 100%;
      transform: translateY(100%);
      z-index: 1;
    }
  }

  &--logo {
    .site-logo {
      height: 56px;
      position: relative;

      @media only screen and (min-width: 992px) {
        height: 82px;
      }

      img {
        margin-top: 14px;
        margin-left: 1px;
        width: 205px;
        max-width: none;
        position: relative;
        z-index: 2;

        @media only screen and (min-width: 992px) {
          width: 315px;
          height: auto;
        }
      }

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0px;
        border: 14px solid transparent;
        border-top-color: $white;
        border-bottom: 0px;
        top: 56px;
        left: 1px;
        z-index: 1;
        position: absolute;

        @media only screen and (min-width: 992px) {
          left: 2px;
        }

        @include media-breakpoint-up(lg) {
          border: 20px solid transparent;
          border-top-color: $white;
          border-bottom: 0px;
          top: 71px;
        }
      }

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0px;
        border: 14px solid transparent;
        border-top-color: $off-black;
        border-bottom: 0px;
        top: 58px;
        left: 1px;
        z-index: 0;
        filter: blur(2px);
        opacity: .6;
        position: absolute;

        @media only screen and (min-width: 992px) {
          left: 2px;
        }

        @include media-breakpoint-up(lg) {
          border: 10px solid transparent;
          border-top-color: $off-black;
          border-bottom: 0px;
          top: 85px;
          left: 11px;
          filter: blur(2px);
          opacity: .6;
        }
      }
    }
  }

  &--navbar {
    width: 75%;

    &--mobile-menu {
      float: right;
    }
  }

  &--list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: space-evenly;
    display: flex;

    .menu--item,
    .header--item {

      &:hover,
      &:focus,
      &:focus-within {
        text-decoration: none;
        color: #222222;
      }

      &.edge {
        position: relative;

        .header--sublist {
          right: 0;
        }
      }

      &.parent--item {
        position: relative;

        &::after {
          display: inline-block;
          margin-left: 4px;
          vertical-align: inherit;
          font-family: $fontawesome;
          content: "\f0d7";
          line-height: 0;
          font-size: 18px;
        }
      }
    }
  }

  &--link {
    font-size: rem-calc(16);
    color: #222222;
    line-height: 82px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: $primary-font;
    text-decoration: none;
    position: relative;

    >a {
      color: #222222;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
      color: #222222;

      >a {
        border-bottom: 4px solid #004B8E;
      }
    }

    ul {
      li {
        line-height: 1.1875rem;
        margin: 20px 0;
      }
    }
  }

  &--icon {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -15px;
      border-top: 6px solid $off-black;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }

    &::after {
      content: '';
      position: absolute;
      left: 10px;
      top: 0;
      border-top: 0px solid #ffffff;
      border-left: 0px solid transparent;
      border-right: 0px solid transparent;
    }
  }

  &--hidden {
    display: none;
  }

  &--sublist {
    position: absolute;
    margin-top: -20px;
    list-style: none;
    background-color: #F4F5F7;
    padding: 0 24px;
    display: none;

    li {
      &:first-child {
        margin-top: 25px;
      }

      margin: 20px 0;

      a {
        text-decoration: none;
        font-size: rem-calc(16);
        line-height: rem-calc(19);
        color: $off-black;
        font-weight: 500;
        font-family: $secondary-font;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.js-showElement {
      display: block;
    }
  }
}

/**Mobile Menu **/
.mobile-menu {
  &--top {
    background-color: #004B8E;

    .mobile-search {
      position: relative;
      margin-top: rem-calc(50);

      &--wrapper {
        display: flex;
        width: 100%;

        img {
          position: absolute;
          right: 13px;
          display: flex;
          align-self: center;
          height: 27px;
          width: 26px;
        }
      }

      &--input {
        width: 100%;
        padding: 11px 0 11px 19px;
        outline: none;
        border-radius: 0;
        border: none;

        &::placeholder {
          color: #222;
          opacity: 1;
        }
      }

      label.visually-hidden {
        color: $white;
        background-color: $defaultblue;
      }
    }

    .accordion-menu {
      margin-top: rem-calc(26);

      &--list {
        list-style: none;
      }

      &--item {
        padding: 20px 0;
        color: $white;
        font-size: rem-calc(24);
        line-height: rem-calc(36);
        font-weight: bold;
        font-family: $primary-font;
        text-decoration: none;
        text-transform: capitalize;

        a {
          text-decoration: none;
          color: $white;
        }

        &.accordion-menu--active {
          text-decoration: underline;
          text-decoration-thickness: 4px;

          .accordion-menu__icon {
            &::before {
              transform: translateY(-50%) rotate(-90deg);
              opacity: 0;
            }

            &:after {
              transform: translateY(-50%) rotate(0);
            }
          }
        }
        &.accordion-menu--parent {
          > a {
            &::after {
              display: inline-block;
              margin-left: 4px;
              vertical-align: inherit;
              font-family: $fontawesome;
              content: "\2b";
              line-height: 0;
              font-size: 18px;
            }
            &.accordion-menu--active {
              &::after {
                content: "\f068";
              }
            }
          }
        }
      }

      &__icon {
        position: relative;
        width: 12px;
        height: 16px;
        transition: 0.2s all;
        margin-left: 7px;
        display: inline-block;

        &::before,
        &::after {
          content: "";
          display: block;
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: 0;
          transition: 0.35s;
          width: 100%;
          height: 3px;
        }

        &::before {
          transform: translateY(-50%);
        }

        &::after {
          transform: translateY(-50%) rotate(90deg);
        }
      }

      &--hidden {
        display: none;
      }

      &--sublist {
        margin-top: rem-calc(25);
        list-style: none;

        li {
          margin: 20px 0;

          a {
            text-decoration: none;
            font-size: rem-calc(16);
            line-height: rem-calc(19);
            color: $white;
            font-weight: 500;
            font-family: $secondary-font;
          }
        }

        &.js-showElement {
          display: block;
        }
      }
    }
  }

  &--bottom {
    background-color: #F4F5F7;

    .student-links {
      margin-top: rem-calc(30);

      a {
        border: 1px solid #004B8E;
        border-radius: 5px;
        text-decoration: none;
        text-align: center;
        padding: 6px 0;
        font-size: rem-calc(14);
        line-height: rem-calc(17);
        font-family: $secondary-font;
        color: #222222;

        &:nth-child(2) {
          margin: 20px 0;
        }
      }
    }
  }

  .quick-links {
    margin-top: rem-calc(24);

    h5 {
      font-size: rem-calc(16);
      line-height: rem-calc(19);
      color: #222222;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 13px 0;

        a {
          color: #222222;
          text-decoration: none;
          font-size: rem-calc(14);
          line-height: rem-calc(17);
          font-family: $secondary-font;
        }
      }
    }
  }

  &.opened {
    z-index: 2000;
    width: rem-calc(300);

    @include media-breakpoint-up(md) {
      width: rem-calc(400);
    }
  }

  .close {
    float: right;
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center;
    cursor: pointer;
    right: 16px;

    &:before,
    &:after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 21px;
      width: 3px;
      background-color: $white;
    }

    &:before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.pushbar_overlay {
  background-color: $black;
}

.scrolling {
  .header--main {
    position: fixed;
    top: 0;
    width: 100%;
  }
}

// Google Search Styles
.utility-gcse {
  .gsc-control-cse {
    position: relative;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    overflow: hidden;
  }

  .gsc-input-box {
    background-color: transparent !important;
    border: none !important;
  }

  form.gsc-search-box,
  table.gsc-search-box {
    margin-bottom: 0 !important;
  }

  .gcse-search-box {
    border: 0 !important;
    background: #387deb !important;
  }

  .gcse-search-box-tools .gcse-search-box .gcse-input {
    padding-right: 0 !important;
  }

  #gs_tti50 {
    padding: 0 !important;
  }



  #gsc-i-id1 {
    width: 100%;
    border: 1px solid #fff !important;
    border-radius: 3px !important;
    padding: 0 40px 0 20px !important;
    line-height: 1;
    box-sizing: border-box !important;
    outline: none !important;
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif !important;
    background: #0A355F !important;
    color: #FFF;
    height: 24px !important;
    margin-top: 1px !important;
    min-width: 230px;
  }

  #gsc-i-id2 {
    width: 100%;
    border: 1px solid #fff !important;
    border-radius: 60px !important;
    padding: 7px 40px 7px 20px !important;
    line-height: 1;
    box-sizing: border-box !important;
    outline: none !important;
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif !important;
    background: $white !important;
    color: #001641;
    height: 35px !important;
    margin-top: 1px !important;
  }

  #gsc-i-id1:focus,
  #gsc-i-id2:focus {
    background: #fff !important;
    color: #001641;
  }

  #gsc-i-id1::-webkit-input-placeholder,
  #gsc-i-id2::-webkit-input-placeholder {
    color: #000 !important;
  }

  #gsc-i-id1::-as-input-placeholder,
  #gsc-i-id2::-as-input-placeholder {
    color: #fff;
  }

  #gsc-i-id1::-moz-placeholder,
  #gsc-i-id2::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  #gsc-i-id1::-moz-placeholder,
  #gsc-i-id2::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  .gsib_b {
    display: none !important;
  }

  .gsc-search-button-v2 {
    padding: 3px 7.5px !important;
    outline: none !important;
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 3px;
    background-color: transparent !important;
    border-color: transparent !important;

    &:hover {
      cursor: pointer;
    }
  }

  .gsc-search-button-v2:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    background-image: none !important;
  }

}

.mobile-search-gcse {
  margin-top: 60px;

  .gsc-control-cse {
    position: relative;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    overflow: hidden;
  }

  .gsc-input-box {
    background-color: transparent !important;
    border: none !important;
  }

  form.gsc-search-box,
  table.gsc-search-box {
    margin-bottom: 0 !important;
  }

  .gcse-search-box {
    border: 0 !important;
    background: #387deb !important;
  }

  .gcse-search-box-tools .gcse-search-box .gcse-input {
    padding-right: 0 !important;
  }

  #gs_tti50 {
    padding: 0 !important;
  }



  #gsc-i-id1 {
    width: 100%;
    border: 1px solid #fff !important;
    border-radius: 3px !important;
    padding: 0 40px 0 20px !important;
    line-height: 1;
    box-sizing: border-box !important;
    outline: none !important;
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif !important;
    background: #0A355F !important;
    color: #FFF;
    height: 24px !important;
    margin-top: 1px !important;
    min-width: 230px;
  }

  #gsc-i-id2 {
    width: 100%;
    border: 1px solid #fff !important;
    border-radius: 0 !important;
    padding: 7px 40px 7px 20px !important;
    line-height: 1;
    box-sizing: border-box !important;
    outline: none !important;
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif !important;
    background: $white !important;
    color: #001641;
    height: 46px !important;
    margin-top: 1px !important;
  }

  #gsc-i-id1:focus,
  #gsc-i-id2:focus {
    background: #fff !important;
    color: #001641;
  }

  #gsc-i-id1::-webkit-input-placeholder,
  #gsc-i-id2::-webkit-input-placeholder {
    color: #000 !important;
  }

  #gsc-i-id1::-as-input-placeholder,
  #gsc-i-id2::-as-input-placeholder {
    color: #fff;
  }

  #gsc-i-id1::-moz-placeholder,
  #gsc-i-id2::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  #gsc-i-id1::-moz-placeholder,
  #gsc-i-id2::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  .gsib_b {
    display: none !important;
  }

  .gsib_a {
    padding: 0 !important;
  }

  table.gsc-search-box td.gsc-input {
    padding-right: 0;
  }

  .gsc-search-button-v2 {
    padding: 3px 7.5px !important;
    outline: none !important;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    background-color: transparent !important;
    border-color: transparent !important;

    &:hover {
      cursor: pointer;
    }

    svg {
      fill: $defaultblue;
      height: 25px;
      width: 25px;
    }
  }

  .gsc-search-button-v2:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    background-image: none !important;
  }
}