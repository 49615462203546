.modal-content {
  border-radius: 0 !important;
  border: solid 10px #E2002E !important;
  .btn-close {
    background: url(../../../assets/images/close-modal.svg) center/23px auto no-repeat;
    opacity: 1;
  }
}
.modal {
  padding: 20px;
}
.modal-fullscreen {
  width: auto;
  .modal-body {
    @media only screen and (min-width: 992px) {
      padding: 160px;
    }
  }
}

.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}