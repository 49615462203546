
.btn {
    font-family: $secondary-black-font, sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 18px;
    border-radius: 0;
    padding: 15px 25px;
    transition: none;
    transition: 120ms all 70ms ease-out;
    @media only screen and (max-width: 1200px) {
        padding: 15px 25px;
    }
    &.btn-block {
        padding: 15px;
        display: block;
        width: 100%;
      }
    &.btn-default {
        background-color: $red;
        border: solid $red 2px;
        color: $off-black;
        min-width: 213px;
        @media only screen and (max-width: 576px) {
            display: block;
            width: 100%;
          }
        &:hover, &:focus {
            background-color: transparent;
            color: $off-black;
            border: solid $white 2px;
            text-decoration: none;
        }
        &.btn-dark-bg {
            &:hover, &:focus {
                background-color: $white;
                color: $off-black;
                border: solid $white 2px;
            }
        }
    }
  &.btn-primary {
      background-color: $defaultblue;
      border: solid $defaultblue 2px;
      color: $white;
      min-width: 213px;
      @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      &:hover, &:focus {
          background-color: $white;
          color: $defaultblue;
          border: solid $defaultblue 2px;
          text-decoration: none;
      }
      &.btn-dark-bg {
          background-color: $white;
          border: solid $white 2px;
          color: $off-black;
          &:hover, &:focus {
              background-color: $red;
              border: solid $red 2px;
              color: $off-black;
          }
      }
  }
  &.btn-secondary {
      background-color: transparent;
      border: solid $defaultblue 2px;
      color: $off-black;
      min-width: 213px;
      @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      &:hover, &:focus {
          background-color: $defaultblue;
          color: $white;
          border: solid $defaultblue 2px;
          text-decoration: none;
      }
      &.btn-dark-bg {
          background-color: transparent;
          border: solid $white 2px;
          color: $white;
          &:hover, &:focus {
              background-color: $white;
              border: solid $white 2px;
              color: $defaultblue;
          }
      }
  }
  &.btn-link {
      color: $defaultblue;
      position: relative;
      text-decoration: none;
      font-weight: 700;
      padding: 15px 0;
      text-transform: none;
      &:hover, &:focus {
          text-decoration: underline;
          color: $defaultblue;
      }
      &::after {
          display: inline-block;
          margin-left: 8px;
          vertical-align: inherit;
          font-family: $fontawesome;
          content: "\f105";
          line-height: 0;
          font-size: 18px;
      }
      &.btn-dark-bg {
          color: $white;
      }
  }
  &.btn-specialty-primary {
        background-color: $white;
        border: solid $off-black 1px;
        color: $off-black;
        border-radius: 0;
        font-weight: normal;
        position: relative;
        padding: 10px 36px 10px 14px;
        font-size: 18px;
        text-transform: initial;
        display: block;
        width: 100%;
        text-align: left;
        position: relative;

    @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      &.btn-block {
        padding: 18px;
      }
      &:hover, &:focus {
          color: $defaultblue;
          text-decoration: underline;
          border: solid $defaultblue 1px;
          outline: solid 1px;
          &::after {
            opacity: 1;
          }
      }
      &::after {
        display: inline-block;
        margin-left: 8px;
        vertical-align: inherit;
        font-family: $fontawesome;
        content: "\f054";
        line-height: 0;
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
      &.btn-dark-bg {
          background-color: transparent;
          border: solid $white 2px;
          color: $white;
          &:hover, &:focus {
              border: solid $white 2px;
              color: $white;
              text-decoration: underline;
          }
      }
  }
  &.btn-specialty-secondary {
      background-color: $white;
      border: solid $defaultblue 1px;
      color: #3264A6;
      border-radius: 3px;
      position: relative;
      padding: 11px 21px;
      font-size: 16px;
      text-decoration: underline;
      text-transform: inherit;
      text-align: left;
      @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      &.btn-block {
        padding: 18px;
      }
      &:hover, &:focus {
          background-color: $secondaryblue;
          border: solid $defaultblue 1px;
          color: #3264A6;
          text-decoration: none;
      }
  }
}

.link-primary {
    color: $defaultblue !important;
    padding: 0 2px;
    &:hover {
        color: $defaultblue !important;
        text-decoration: underline;
        text-decoration-color: $red;
        text-decoration-thickness: 3px;
    }
    &.dark-bg {
        color: $white !important;
        padding: 0 2px;
        &:hover {
            text-decoration: underline;
            text-decoration-color: $red;
            text-decoration-thickness: 3px;
        }
    }
}

.inline-buttons {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin-right: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        @media only screen and (max-width: 576px) {
            margin-top: 0;
            margin-bottom: 15px;
            margin-right: 0;
            display: block;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.program-detail-meet-us-btn {
    @media only screen and (max-width: 576px) {
        text-align: center;
        margin-bottom: 30px;
    }
}