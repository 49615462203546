
.recent-events-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        background-color: $white;
        margin-bottom: 16px;
        padding: 14px 18px;
        &:hover, &:focus-within {
            outline: solid 3px $red;
            .recent-events-content {
                .duration {
                }
                .recent-events-title {
                    a {
                        text-decoration: underline;
                        color: $defaultblue;
                    }
                }
            }
        }
        .recent-events-date {
            font-family: $secondary-font, sans-serif;
            font-variation-settings: 'wght' 700;
            float: left;
            text-align: center;
            margin-right: 24px;
            width: 30px;
            .month {
                font-size: 14px;
                text-transform: uppercase;
                display: block;
            }
            .date {
                font-size: 24px;
                display: block;
                line-height: 1;
            }
        }
        .recent-events-content {
            margin-left: 54px;
            .duration {
                margin-bottom: 10px;
                font-size: 16px;
            }
            .recent-events-title {
                margin-bottom: 10px;
                a {
                    font-family: $primary-bold-font;
                    color: $off-black;
                    font-size: 18px;
                    text-decoration: none;
                }
            }
        }
        p {
            margin-bottom: 0;
            a.link-learn-more {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

    }
    &.recent-events-list-secondary {
        li {
            background-color: transparent;
            margin-bottom: 0;
            padding: 5px 0;
            &:hover, &:focus-within {
                outline: none;
            }
        }
    }
    &.recent-events-list-minimal {
        li {
            .recent-events-content {
                margin-left: 10px;
            }
        }
    }
}