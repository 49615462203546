/**
* === Pagination ===
* A line of page numbers to select through
*/

.pagination-wrapper{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    flex-direction: column;

  }

  .page-indicator{
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      float: none !important;
      position: relative;
      right: inherit;
      top: inherit;
      margin-top: 22px;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 30px;
  
    
    .prev{
      z-index: 99;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      //justify-content: space-between;
      justify-content: center;
    }
  
    i {
      line-height: normal;
      font-size: 12px;
    }
      li.page-item {
        padding: 0 15px 0 0;
        @include media-breakpoint-down(sm) {
          display: flex;
          padding:0;
          // &:nth-child(5n) {
          //   display: none;
          // }
        }  
        .page-link {
          border-radius: 0;
          border: solid 1px transparent;
          background-color: $white;
          font-weight: 400;
          font-size: 14px;
          color: $lightblue;
          line-height: normal;
          text-decoration: none !important;
          transition: none;
          cursor: pointer;
          &.page-link-more {
            color: #272727;
          }
          &.page-link-previous, &.page-link-next {
            color: #222222;
            background-color: #F4F4F4;
            border: solid 1px #222222;
            cursor: pointer;
            &:hover {
              border: solid 1px $defaultblue;
              background-color: $defaultblue;
              color: $white;
            }
          }
          &.page-link-previous {
            &.page-link-skip {
              span {
                border-left: solid 2px $defaultblue;
                padding-left: 2px;
                margin-left: -3px;
                margin-right: -3px;
              }
              &:hover span, &:focus span {
                border-left: solid 2px $white;
              }
            }
           
            @include media-breakpoint-down(sm) {
              &.prev{
                margin-left:rem-calc(12);
                z-index: 90;
              }
            }
            
          }
          &.page-link-next {
            &.page-link-skip {
              span {
                border-right: solid 2px $defaultblue;
                padding-right: 2px;
                margin-left: -3px;
                margin-right: -3px;
              }
              &:hover span, &:focus span {
                border-right: solid 2px $white;
              }
            }
            &.next{
              margin-right:rem-calc(12);
            }
          }
          &.active {
            z-index: inherit;
          }
        }
  
        .page-link:hover, .page-link:focus {
          background-color: $defaultblue;
          color: $white;;
        }
  
        .page-link.active {
          background-color: $defaultblue;
          border: solid 1px $defaultblue;
          color: $white;
        }
        &.active {
          a.page-link {
            background-color: $defaultblue !important;
          }
        }
      }
  
      li.page-first, li.page-last {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        .page-link {
          background-color: transparent;
          padding: 0 0;
          border: none;
          font-size: 14px;
          color: #042D61;
          text-decoration: underline !important;
        }
  
        .page-link:hover, .page-link:focus {
          background-color: transparent;
          opacity: .6;
          color: #042D61;
        }
  
        .page-link.active {
          background-color: transparent;
          color: $darker-gray;
        }
      }

      li.page-last {
        a.page-link:hover, a.page-link:focus {
          border: none;
        }
      }
  
      li.pages-random {
        .page-link {
          border: none;
          text-decoration: none;
          background-color: transparent;
        }
  
        .page-link:hover, .page-link:focus {
          background-color: transparent;
          color: $darker-gray;
          border: none;
        }
  
        .page-link.active {
          background-color: transparent;
          color: $darker-gray;
        }
      }
  }

  &.news-pagination-wrapper {
    .fas{
      cursor: pointer;
      pointer-events: none;
    }
    .pagination{
      margin:50px 0;
     
    }
  }

}

.page-academic-programs .pagination-wrapper, .page-news-listing .pagination-wrapper {
  @media only screen and (min-width: 992px) {
    justify-content: right;
  }
}

/* .pagination li.page-item.active {
    
    background-color: #001641;
    color: #ffffff;
} */
.pagination > .page-item.active .page-link {
  background-color: #001641 !important;
  color: #ffffff !important;
}
a.clear-program {
   margin: 0 5px;
}
.clear_second {
   padding: 15px 0px;
   margin-right: -4px;
}
.clear-filter .text {
   text-decoration: underline;
}
