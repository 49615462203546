.masked-wrapper {
  background-image: url('../../../assets/images/pscc_img_8.png');
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
  position: relative;
  background-position: 50% 100%;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    height: 600px;
  }
  @media only screen and (max-width: 768px) {
    height: 400px;
  }
  &.masked-wrapper-secondary {
    height: 600px;
    #triangle-holder-top {
      display: none;
    }
  }
}

#triangle-1 {
  width: 0;
  height: 0;
  border-bottom: 20vw solid $defaultblue;
  border-right: 100vw solid transparent;
  float: left;

}

#triangle-2 {
  width: 0;
  height: 0;
  border-bottom: 20vw solid $defaultblue;
  border-left: 100vw solid transparent;
}

.triangle-down {
  box-sizing: content-box;
  width: 50%;
  height: 150px;
  padding-left: 50%;
  padding-top: 0;
  overflow: hidden;
}

.triangle-down div {
  width: 0;
  height: 0;
  margin-left: -1000px;
  margin-top: 0;
  border-left: 1000px solid transparent;
  border-right: 1000px solid transparent;
  border-top: 150px solid $defaultblue;
  @media only screen and (max-width: 992px) {
    margin-left: -600px;
    border-left: 600px solid transparent;
    border-right: 600px solid transparent;
  }
  @media only screen and (max-width: 768px) {
    margin-left: -400px;
    border-left: 400px solid transparent;
    border-right: 400px solid transparent;
  }
}

#triangle-holder {
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  @media only screen and (max-width: 992px) {
    bottom: 0;
  }
}

#triangle-holder-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
