/**
* === Footer ===
* Styles associated with the Footer block
*/
.site-footer {
    background-color: $white;
    border-top: solid 7px $red;
    .primary-footer-wrap {
        padding: 30px 15px;
        .footer-info-col-left {
            @media only screen and (min-width: 769px) {
                width: 28%;
            }
            @media only screen and (max-width: 768px) {
                border-bottom: solid 1px $white;
                margin-bottom: 30px;
                padding-bottom: 20px;
            }
            
        }
        .footer-info-col-right {
            @media only screen and (min-width: 769px) {
                width: 72%;
            }
        }
        .footer-heading, .footer-address, .footer-phone, .footer-email {
            color: $off-black;
            a {
                color: $defaultblue;
            }
        }
        .footer-heading {
            font-size: 18px;
            font-family: $secondary-font, sans-serif;
            font-variation-settings: 'wght' 700;
        }
        .footer-address {
            font-size: 14px;
            margin-bottom: 12px;
            span {
                display: block;
            }
        }
        .footer-phone {
            font-size: 16px;
            font-variation-settings: 'wght' 700;
            a {
                font-variation-settings: 'wght' 300;
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: $red;
                    text-decoration-thickness: 3px;
                }
            }
        }
        .footer-email {
            font-size: 16px;
            font-variation-settings: 'wght' 700;
            a {
                font-variation-settings: 'wght' 300;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    
        .footer-primary-menu {
            list-style-type: none;
            padding: 0;
            margin: 0;
            @media only screen and (min-width: 769px) {
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
                padding: 0 0 0 40px;
            }
            @media only screen and (max-width: 768px) {
                text-align: center;
                padding-top: 60px;
            }
            li {
                margin-bottom: 14px;
                a {
                    color: $defaultblue;
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 22px;
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $red;
                        text-decoration-thickness: 3px;
                    }
                }
            }
        }
    
        .footer-social {
        .social-icon {
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                @media only screen and (max-width: 768px) {
                    width: 100%;
                    margin: auto;
                    display: flex;
                }
                li {
                    margin: 0 2px;
                    padding: 0;
                    display: inline-block;
                    text-align: -webkit-match-parent;
                    @media only screen and (max-width: 768px) {
                        flex: 1;
                        text-align: center;
                    }
                    a {
                        .fa-brands {
                            width: 37px;
                            height: 37px;
                            &::before{
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
            
                        .fa-brands {
                            background: transparent;
                            color: $defaultblue;
                            display: inline-block;
                            cursor: pointer;
                            margin: 0;
                            padding: 6px 0;
                            text-align: center;
                            position: relative;
                            z-index: 1;
                            overflow: hidden;
                            border: solid 1px transparent;
                            -webkit-transform: translateZ(0);
                        }
                        &:hover {
                            .fa-brands {
                                background: $white;
                                color: $off-black;
                                border: solid 1px $off-black;
                            }
                        }
                        &.x-twitter {
                            border: solid 1px transparent;
                            display: block;
                            img {
                                height: 35px;
                                padding: 13px;
                                display: block;
                            }
                            &:hover {
                                border: solid 1px $off-black;
                            }
                        }
                    }
                }
            }
        }
        }
        .footer-logo {
            max-width: 256px;
            margin-bottom: 20px;
        }
    }
    .secondary-footer-wrap {
        padding: 18px 15px;
        background-color: #F4F5F7;
        color: $off-black;
        .container {
            .footer-logos {
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                li {
                    flex: 1;
                    @media only screen and (max-width: 768px) {
                        flex: 1 0 50%;
                        margin-bottom: 15px;
                    }
                }
            }
            .footer-copyright {
                list-style-type: none;
                padding: 0;
                margin: 24px 0;
                display: flex;
                @media only screen and (max-width: 768px) {
                    display: block;
                    text-align: center;
                    margin-bottom: 40px;
                }
                li {
                    @media only screen and (max-width: 768px) {
                        flex: 1;
                    }
                    &:first-child {
                        font-size: 14px;
                        @media only screen and (max-width: 768px) {
                            margin-right: 0;
                            margin-bottom: 40px;
                        }
                    }
                    a {
                        color: $off-black;
                        font-size: 14px;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    &.footer-terms-conditions {
                        @media only screen and (min-width: 769px) {
                            flex: 1;
                        }
                        ul {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;
                            
                            @media only screen and (min-width: 769px) {
                                float: right;
                            }
                            li {
                                
                                @media only screen and (max-width: 768px) {
                                    margin: 0 0 15px;
                                    display: block;
                                }
                                @media only screen and (min-width: 769px) {
                                    margin: 0 0 0 6px;
                                    display: inline;
                                }
                                &::after {
                                    @media only screen and (min-width: 769px) {
                                        content: "\f111";
                                        font-family: $fontawesome;
                                        font-size: 6px;
                                        margin-left: 6px;
                                    }
                                    
                                }
                                &:last-child {
                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            p {
                font-size: 14px;
                a {
                    color: $off-black;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}