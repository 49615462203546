/**
* === Tabs ===
*/
.tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    @media screen and (max-width: 45em) {
        margin-left: 0;
        margin-right: 0;
    }
    label {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        display: block;
        padding: 11px 22px;
        margin-right: 16px;
        cursor: pointer;
        background: #E4E4E4;
        -webkit-transition: background ease 0.2s;
        transition: background ease 0.2s;
        position: relative;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-family: $primary-bold-font;
        &:hover {
            text-decoration: underline;
            color: $defaultblue;
        }
        @media only screen and (min-width: 721px) {
            flex: 1;
            margin-right: 10px;
            border-radius: 0;
            border-top: solid 2px #909090;
            border-left: solid 2px #909090;
            border-right: solid 2px #909090;
            border-bottom: solid 2px #909090;
            margin-bottom: -2px;
            text-align: left;
            &:hover {
                border-top: solid 2px $defaultblue;
                border-left: solid 2px $defaultblue;
                border-right: solid 2px $defaultblue;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
        @media (max-width: 45em) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            width: 100%;
            margin-right: 0;
            margin-top: 0.2rem;
            &::after {
                content: "\f0dd";
                font-family: "Font Awesome 6 Free";
                position: absolute;
                right: 20px;
                font-size: 18px;
                top: calc(50% - 16px);
            }
        }
    }
    .tab {
        -webkit-box-ordinal-group: 100;
        -webkit-order: 99;
        -ms-flex-order: 99;
        order: 99;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: 100%;
        display: none;
        padding: 48px 32px;
        background: white;
        border: solid 2px #909090;
        @media (max-width: 45em) {
            padding: 25px 22px;
        }
    }
    input[type="radio"] {
        position: absolute;
        opacity: 0;
    }
    input[type="radio"]:checked + label { 
        background: $white; 
        color: $defaultblue;
        border-radius: 0;
        border-top: solid 2px #909090;
        border-left: solid 2px #909090;
        border-right: solid 2px #909090;
        border-bottom: transparent;
        margin-bottom: -2px;
        @media (max-width: 45em) {
            background: $white; 
            color: $defaultblue;
            &::after {
                content: "\f0de";
                top: calc(50% - 8px);
            }
        }
        @media (min-width: 45em) {
            &::after {
                content: "\f0d7";
                font-size: 40px;
                font-family: "Font Awesome 6 Free";
                position: absolute;
                left: calc(50% - 10px);
                bottom: -30px;
                color: $defaultblue;
                display: none;
            }
        }
    }
    input[type="radio"] + label {
        @media (max-width: 45em) {
            border-radius: 0;
            background: #F4F5F7; 
            color: $off-black;
            border: solid 2px #909090;
        }
    }
    input[type="radio"]:checked + label + .tab { 
        display: block; 
    }
    .tab,  
    label {
        @media (max-width: 45em) {
            -webkit-box-ordinal-group: NaN;
            -webkit-order: initial;
            -ms-flex-order: initial;
            order: initial;
        }
    }
}

.tab-block-full-width-container {
    background: $white;
    @media only screen and (max-width: 720px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media only screen and (min-width: 721px) {
        border-top: solid 2px #909090;
        margin-top: 53px;
    }
    
    .tabs {
        @media only screen and (min-width: 721px) {
            margin-top: -50px;
        }
        .tab {
            @media only screen and (min-width: 721px) {
                border-left: none;
                border-right: none;
                border-bottom: none;
                padding: 48px 0;
            }
         
        }
        label {
            white-space: nowrap;
            text-align: center;
            @media only screen and (max-width: 720px) {
                text-align: left;
            }
        }
    } 
}