/*
Full Width Video
================================== */
.video-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Video overlay and content */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  /* Allows right click menu on the video */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.video-hero--content {

  position: relative;
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #ffffff;
  }

  h2 {
    font-size: rem-calc(34);
    margin: 0 0 rem-calc(10);
  }

  p {
    margin: 0;
  }
  .inner {
    padding: 27px 37px 27px 75px;
    background-color: $white;
    max-width: 800px;
    position: absolute;
    bottom: 5%;
    left: -3px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    h1 {
      color: $defaultblue;
      font-size: 48px;
      a {
        color: $white;
        text-decoration: underline;
        text-decoration-color: $red;
      }
    }
    p {
      font-size: 18px;
      color: $off-black;
      margin-bottom: 28px;
    }
  }
}

.wp-block-popup-video {
  .video-hero--content {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

/* CSS from jQuery Background Video plugin */

/**
 * Set default positioning as a fallback for if the plugin fails
 */
.jquery-background-video-wrapper {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

.jquery-background-video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/**
 * Fade in videos
 * Note the .js class - so non js users still
 * see the video
 */
.js .jquery-background-video {
  opacity: 0;
  -webkit-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}

.js .jquery-background-video.is-visible {
  opacity: 1;
}

/**
 * Pause/play button
 */
.jquery-background-video-pauseplay {
  position: absolute;
  background: transparent;
  border: none;
  box-shadow: none;
  width: rem-calc(20);
  height: rem-calc(20);
  top: rem-calc(64) !important;
  left: rem-calc(15) !important;
  padding: 0;
  cursor: pointer;
  outline: none !important;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }

  @include mq('phablet', 'max') {
    top: rem-calc(32) !important;
  }
}

.front-hero-section {
  .jquery-background-video-pauseplay {
    @include mq('phablet', 'max') {
      display: none;
    }
  }
}

.jquery-background-video-pauseplay span {
  display: none;
}

.jquery-background-video-pauseplay:after,
.jquery-background-video-pauseplay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.jquery-background-video-pauseplay.play:before {
  border-top: rem-calc(10) solid transparent;
  border-bottom: rem-calc(10) solid transparent;
  border-left: rem-calc(15) solid #FFF;
}

.jquery-background-video-pauseplay.pause:before,
.jquery-background-video-pauseplay.pause:after {
  border-top: rem-calc(10) solid #FFF;
  border-bottom: rem-calc(10) solid #FFF;
  border-left: rem-calc(5) solid #FFF;
}

.jquery-background-video-pauseplay.pause:after {
  left: rem-calc(10);
}
