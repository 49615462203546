.page-directory-listing, .page-news-listing {
    h1.pb-5 {
        text-transform: inherit;
    }
    //employee directory sidebar
    .form-control-lg, .form-select.filters-select {
        margin-bottom: 10px;
        border-radius: 3px;
        border: solid 1px #9A9A9A;
        background-color: #EAEAEA;
        font-size: 14px;
        padding: 7px 16px;
        color: #222222;
        &::placeholder {
            color: #222222;
        }
    }
    .form-control-lg {
        padding: 14px 20px;
    }
    .btn.btn-primary.clear-filters {
        background-color: transparent;
        color: #3264A6;
        font-family: $primary-bold-font;
        text-transform: capitalize;
        width: auto !important;
        margin: auto;
        padding: 8px 12px;
    }
    //employee directory container
    .employee-container {
        border-left: solid 1px #D8D8D8;
        @media screen and (min-width: 768px) {
            padding-left: 30px;
        }
        .card {
            border-radius: 0;
            border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
            border-left: none;
            border-right: none;
            border-top: none;
            margin-bottom: 0 !important;
            @media screen and (min-width: 768px) {
                padding: 30px 0 30px 15px;
            }
            .card-title {
                font-family: $primary-bold-font;
                font-variation-settings: "wght" 500;
                color: #3264A6;
                margin-bottom: 0;
                a {
                    text-decoration: none;
                    color: #3264A6;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .card-text {
                margin-bottom: 0;
                a {
                    text-decoration: none;
                    color: #3264A6;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &:first-child {
                border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
            }
        }
    }
    .employee-sidebar {
        padding-bottom: 20px;
        h4 {
            margin-bottom: 15px;
        }
        @media screen and (min-width: 768px) {
            padding-right: 20px;
        }
    }
    .pagination-wrapper {
        //margin-top: 60px;
    }
    .directory-listing-filter-wrap {
        list-style-type: none;
        margin: 0 0 30px;
        padding: 0;
        display: flex;
        gap: 33px;
        @media screen and (max-width: 992px) {
            display: block;
        }
        .directory-listing-filter-left-col, .directory-listing-filter-right-col {
            flex: 1 0 48%;
            .form-control-lg {
                padding: 4px 40px 4px 20px;
                min-height: auto;
                background-color: $white;
                position: relative;
                border-color: $defaultblue;
                background-image: url(../../images/submit-arrow-mobile.svg);
                background-repeat: no-repeat;
                background-position: 99% 2px;
                background-size: 30px 30px;
                font-size: 18px;
                &::placeholder {
                    opacity: .55;
                }
                &:focus{
                    background-image: none !important; 
                }
            }
        }
        label {
            font-variation-settings: "wght" 700;
            margin-bottom: 8px;
        }
    }
    .directory-listing-areas-bg-color {
        padding: 10px;
        margin-bottom: 54px;
        label {
            font-variation-settings: "wght" 700;
            margin-bottom: 8px;
        }
        .directory-listing-areas-wrap {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            gap: 18px;
            @media screen and (max-width: 992px) {
                display: block;
            }
            .directory-listing-areas-col {
                flex: 1 0 22%;
                @media screen and (max-width: 992px) {
                    margin-bottom: 15px;
                }
                .btn-specialty-primary {
                    text-transform: initial;
                    padding: 6px;
                    border: solid $defaultblue 1px;
                }
            }
        }
        @media screen and (max-width: 992px) {
            label.filter-heading {
                font-size: 18px;
                margin-bottom: 0;
                position: relative;
                display: block;
                &::after {
                    display: inline-block;
                    margin-left: 8px;
                    vertical-align: inherit;
                    font-family: "Font Awesome 6 Free";
                    content: "\2b";
                    line-height: 0;
                    position: absolute;
                    right: 0px;
                    top: 13px;
                }
            }
            .directory-listing-areas-wrap {
                display: none;
                }
            &.dropdown-filter.active {
                label.filter-heading {
                    &::after {
                        content: "\f068";
                    }
                }
                .directory-listing-areas-wrap {
                    display: block;
                    padding-top: 13px;
                }
            }
        }
    }          
}

.align-form-label {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 992px) {
        margin-bottom: 30px;
    }
    li {
        position: relative;
        width: 100%;
        &:first-child {
            width: 100px;
            float: left;
            max-width: fit-content;
            padding-right: 15px;
            @media screen and (max-width: 992px) {
                float: none;
                width: 100%;
            }
        }
        input, select {
            width: -webkit-fill-available;
        }
        label {
            color: $defaultblue;
            font-family: $primary-bold-font;
            font-size: 24px;
        }
        
        .clearable__clear{
            display: none;
            position: absolute;
            right: 3px;
            top: -7px;
            padding: 0 8px;
            font-style: bold;
            font-size: 2rem;
            user-select: none;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                right:0;
                top:rem-calc(36);
            }
        }
    }
}