.img-gradient-wrap {
  position: relative;
  text-decoration: none !important;
  &:hover{
    .text-bottom {
      padding-left: 54px !important;
      text-decoration: underline !important;
      transition:0.2s;
      &:before{
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 14px;
        margin-top: -14px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 29px solid $secondary_color;
        transform: rotate(-90deg);
      }
    }
  }

  img {
    display: block;
  }

  .img-gradient {
    position: relative;
    margin: 0;
    padding: 0;
  }

  .img-caption {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 140px;
    text-align: left;
    color: $white;
    font-weight: bold;
    background: transparent linear-gradient(180deg, #00133800 0%, #001338F8 59%, #001338 100%) 0% 0% no-repeat padding-box;

    .text-bottom {
      position: absolute;
      bottom: 0;
      padding: 15px 0 15px 20px;
      p {
        margin-bottom: 0;
        font-family: 'montserrat';
        font-size: 18px;
        font-weight: 700;
        color: $white;
      }
    }
  }

}

.align-right-img {
  float: right;
  margin: 10px 0 10px 30px;
}

.align-left-img {
  float: left;
  margin: 10px 30px 10px 0;
  @media only screen and (max-width: 576px) {
    float: none;
  }
}