
ul.recent-news-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    &.recent-news-list-minimal {
        display: block;
        li {
            box-shadow: none;
        }
    }
    @media only screen and (min-width: 976px) {
        display: flex;
        gap: 17px;
    }
 li {
    background-color: $white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
    padding: 15px;
    margin-bottom: 20px;
    @media only screen and (min-width: 976px) {
        flex: 1;
    }
    @media only screen and (max-width: 975px) {
        &:last-child {
            display: none;
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 10px 15px;
    }
    .recent-news-img {
        width: 126px;
        float: left;
        margin-right: 20px;
        @media only screen and (max-width: 768px) {
            float: none;
            margin-bottom: 10px;
        }
        a.img-hover-zoom-link {
            .img-hover-zoom {
                overflow: hidden;
                img {
                    transition: transform .5s ease;
                    max-width: 100%;
                    vertical-align: middle;
                  }
                  &:hover img {
                    transform: scale(1.2);
                  }
            }
        }
    }
    .recent-news-content {
        margin-left: 146px;
        &.recent-news-content-img-none {
            margin-left: 0;
        }
        @media only screen and (max-width: 768px) {
            margin-left: 0;
        }
        .recent-news-date {
            font-size: 16px;
            margin-bottom: 6px;
        }
        .recent-news-title {
            margin: 0;
            line-height: 24px;
            a {
                font-size: 18px;
                color: $off-black;
                text-decoration: none;
                font-family: $primary-bold-font;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        p {
            font-size: 16px;
        }
    }
    &:hover, &:focus-within {
        outline: solid 3px $red;
        .recent-news-img {
            a.img-hover-zoom-link {
                .img-hover-zoom {
                    img {
                        transition: transform .5s ease;
                      }
                      img {
                        transform: scale(1);
                      }
                }
            }
        }
        .recent-news-content {
            .recent-news-date {
                font-size: 16px;
            }
            .recent-news-title {
                a {
                    text-decoration: underline;
                    color: $defaultblue !important;
                }
            }
            p {
                font-size: 16px;
            }
        }
    }
 }
 &.recent-news-list-minimal {
        li {
            padding: 10px;
            background-color: transparent;
            &:hover, &:focus-within {
                outline: none;
            }
        }
        .recent-news-content {
            margin-left: 0;
            .recent-news-title {
                font-size: 18px;
            }
        }
    }

    &.active {
        li {
            @media only screen and (max-width: 975px) {
                &:last-child {
                    display: block;
                }
            }
        }
    }
}



.news-listing-cards {
    ul.recent-news-list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        @media only screen and (max-width: 768px) {
            display: block;
        }
        li {
            flex: 1 0 48%;
            margin-bottom: 0;
            @media only screen and (max-width: 769px) {
                margin-bottom: 20px;
            }
        }
        &.news-listing-page-listing {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: 0 -10px;
            list-style-type: none;
            gap: 0;
            @media only screen and (min-width: 1330px) {
                margin: 0 -30px;
            }
            li {
                width: calc(25% - 0px); /* Subtracting the gutter from the width */
                box-sizing: border-box;
                padding: 10px;
                box-shadow: none;
                flex: none;
                @media only screen and (min-width: 1330px) {
                    padding: 30px;
                    border-right: solid 1px #E4E4E4;
                    margin-bottom: 60px;
                }
                &:nth-child(4n) {
                    margin-right: 0; /* Removes the right margin for every fourth item */
                    border-right: none;
                  }
                @media only screen and (max-width: 992px) {
                    width: calc(50% - 0px);
                    margin-right: 0;
                    margin-bottom: 30px; /* Adds a vertical gutter for smaller screens */
                }
                @media only screen and (max-width: 600px) {
                    width: calc(100% - 0px);
                    margin-right: 0;
                    margin-bottom: 30px; /* Adds a vertical gutter for smaller screens */
                }
                .recent-news-img {
                    float: none;
                    margin: 0;
                    width: 100%;
                    a.img-hover-zoom-link .img-hover-zoom img {
                        width: 100%;
                    }
                }
                .recent-news-content {
                    margin: 0;
                    .recent-news-date {
                        margin: 12px 0;
                    }
                    .recent-news-title {
                        margin-bottom: 12px;
                    }
                }
                &:hover, &:focus-within {
                    outline: none;
                    .recent-news-content {
                        .recent-news-title {
                            a {
                                color: $off-black;
                            }
                        }
                    }
                }
            }
        }
    }
}

.news-events-description-max-width {
    max-width: 593px;
    margin: 0 auto 30px;
    font-size: 18px;
}
.new-events-list-max-width {
    max-width: 1168px;
}

.news-events-cta-btns-max-width {
    max-width: 915px;
    @media only screen and (min-width: 769px) {
        margin-bottom: 132px;
    }
    
}

.featured-news-post-wrap {
    @media only screen and (max-width: 992px) {
        &.secondaryblue-bg.p-5 {
            padding: 17px 13px !important;
        }
    }
    
    img {
        @media only screen and (max-width: 992px) {
            margin-bottom: 15px;
        }
    }
    .featured-news-post-date {
        font-size: 14px;
        color: $white;
        margin-top: 8px;
    }
    h2 {
        line-height: 38px;
        margin-top: 8px;
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    p.lead {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 28px;
        color: $white;
    }
    .btn.btn-link {
        font-size: 16px;
        text-transform: inherit;
        color: #222222;
        font-variation-settings: "wght" 400;
    }
}

.sidebar-offset {
    ul.recent-news-list li .recent-news-content .recent-news-title a {
        font-size: 18px;
    }
}

.news-events-front-page-section {
    h1, h2, h3, h4, h5, h6 {
        &.news-events-front-page-heading {
            font-family: $primary-bold-font;
            font-size: 55px;
        }
    }
    .news-events-front-page-heading {
        font-family: $primary-bold-font;
        font-size: 55px;
        color: $defaultblue;
        margin-bottom: 0.5rem;
    }

    h1, h2, h3, h4, h5, h6 {
        &.news-events-front-page-news-heading, &.news-events-front-page-events-heading {
            font-size: 24px;
            font-family: $primary-bold-font;
            color: $defaultblue;
        }
    }
    .news-events-front-page-news-heading, .news-events-front-page-events-heading {
        font-size: 24px;
        font-family: $primary-bold-font;
        color: $defaultblue;
        margin-bottom: 0.5rem;
    }
}

.featured-news-posts-listing {
    list-style-type: none;
    padding: 0;
    margin: 0;
    @media only screen and (max-width: 768px) {
        margin-top: 30px;
    }
    li {
        padding: 25px 0;
        border-top: solid 1px rgba(255, 255, 255, 0.3);
        img {
            float: left;
            max-width: 213px;
            @media only screen and (max-width: 768px) {
                max-width: 134px;
            }
        }
        .listing-content {
            margin-left: 233px;
            @media only screen and (max-width: 768px) {
                margin-left: 154px;
            }
            .featured-date {
                font-size: 14px;
                color: $white;
                margin-bottom: 12px;
            }
            .featured-listing-heading {
                a {
                    font-size: 18px;
                    font-family: $primary-bold-font;
                    color: $white;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.event-listing-toggle {
    display: block;
    padding: 0;
    line-height: 0;
    font-size: 0;
    background: 0 0;
    color: transparent;
    border: none;
    margin: 30px auto 60px;
    @media only screen and (min-width: 976px) {
        display: none;
    }

    .event-listing-toggle-text {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        -webkit-clip-path: inset(50%) !important;
        clip-path: inset(50%) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
      }
      
      .event-listing-toggle-icon {
        font-family: "Font Awesome 6 Free" !important;
        background: #ffffff;
        width: 55px;
        height: 55px;
        padding: 17px 0 0;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
        opacity: 1;
        display: block;
        color: #000;
        font-size: 24px;
        line-height: 1;
        &:hover, &:focus {
            background: $defaultblue;
            color: $red;
            outline: solid 2px $red;
        }
        &::before {
            content: "\2b" !important;
        }
      }

      &.active {
        .event-listing-toggle-icon::before {
            content: "\f068" !important;
        }
      }
  }