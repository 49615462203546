.cta-banner {
    padding: 43px;
    background-color: $defaultblue;
    .fit-content-center {
        max-width: fit-content;
        margin: auto;
    }
    /* border: 3px solid $red; */
    @media only screen and (max-width: 768px) {
        padding: 20px 15px;
    }
    &.cta-banner-transparent {
        background-color: rgba(0, 22, 65, 0.91);
        text-align: center;
        max-width: 1285px;
        margin: auto;
        h1, h2, h3, h4, h5, h6, p {
            color: $white;
        }
        .form-group {
            margin: auto;
            max-width: 444px;
        }
        .btn {
            margin-top: 23px;
        }
        p {
            max-width: 715px;
            margin: 15px auto;
            a {
                color: $white;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .form-select {
            border-radius: 3px;
        }
    }
    &.cta-banner-write-your-story {
        position: relative;
        .write-your-story-img {
            display: none;
            @media only screen and (max-width: 576px) {
                display: block;
                margin-bottom: -30px;
                width: 100%;
            }
        }
        .write-your-story-wrap {
            @media only screen and (max-width: 576px) {
                display: none;
            }
            .write-your-story-heading {
                height: 142px;
                width: 442px;
                background-image: url(../../images/write-your-story.svg);
                text-indent: -9999px;
                overflow: hidden;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
                color: $white;
                margin-bottom: -25px;
                margin-top: -25px;
                @media only screen and (max-width: 768px) {
                    height: 88px;
                    width: 100%;
                }
            }
          }
          p {
            font-size: 38px;
            color: $white;
            margin: 0;
            line-height: 38px;
            @media only screen and (max-width: 900px) {
                margin-top: 30px;
                margin-bottom: 45px;
            }
          }
          .btn {
            @media only screen and (min-width: 901px) {
                position: absolute;
                right: 71px;
                top: 50%;
                transform: translate(0, -50%);
                padding: 15px 76px;
            }
            @media only screen and (max-width: 1200px) {
                right: 43px !important;
            }
            @media only screen and (max-width: 900px) {
                padding: 15px;
                display: block;
                width: 100%;
            }
          }
    }
    &.cta-banner-sidebar {
        padding: 30px 20px 20px;
        margin-bottom: 30px;
        border: none;
        h1, h2, h3, h4, h5, h6, p {
            color: $white;
            font-size: 32px;
        }
        h3, .h3 {
            line-height: 32px;
        }
        p {
            font-size: 18px;
            margin-bottom: 28px;
        }
        .btn {
            padding: 15px;
        }
        &.cta-banner-sidebar-program {
            background-color: $secondaryblue;
            border: solid 3px $defaultblue;
            h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
                color: $defaultblue;
                text-decoration: underline;
                margin-bottom: 15px;
                font-size: 24px;
            }
            p {
                color: $defaultblue;
                margin-bottom: 8px;
            }
            .btn:first-child {
                margin-top: 15px;
            }
        }
    }
    &.cta-banner-related {
        h1, h2, h3, h4, h5, h6, p {
            color: $white;
        }
        p {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
    &.cta-banner-social {
        position: relative;
        padding: 65px 43px;
        h1, h2, h3, h4, h5, h6, p, .h1, .h2, .h3, .h4, .h5, .h6 {
            color: $white;
            font-size: 32px;
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
        }
        .cta-social {
            @media only screen and (min-width: 901px) {
                position: absolute;
                right: 71px;
                top: 50%;
                transform: translate(0, -50%);
            }

            .social-icon {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    @media only screen and (max-width: 900px) {
                        width: 100%;
                        margin: 45px 0 0;
                        display: inline-block;
                    }
                    li {
                        margin: 0 2px;
                        padding: 0;
                        display: inline-block;
                        text-align: -webkit-match-parent;
                        @media only screen and (max-width: 900px) {
                            text-align: center;
                        }
                        &:first-child {
                            text-align: left;
                        }
                        &:last-child {
                            text-align: right;
                        }

                        a {
                            .fa-brands {
                                width: 37px;
                                height: 37px;
                                &::before{
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            }
                
                            .fa-brands {
                                background: transparent;
                                color: $white;
                                display: inline-block;
                                cursor: pointer;
                                margin: 0;
                                padding: 6px 0;
                                text-align: center;
                                position: relative;
                                z-index: 1;
                                overflow: hidden;
                                border-radius: 50%;
                                border: solid 1px $white;
                                -webkit-transform: translateZ(0);
                            }
                            &:hover {
                                .fa-brands {
                                    background: $white;
                                    color: $defaultblue;
                                }
                            }
                        }
                    }
                }
            }
            }
    }

}

.fit-content-center {
    max-width: fit-content;
    margin: auto;
}

.cta-banner {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ol, ul {
        color: $white;
      }
      ol.ordered-list-default, ul.unordered-list-default {
        color: $white;
        li {
            a {
                color: $white;
            }
        }
        li::before {
          background: $white;
        }
        ul li::before {
          background: $defaultblue;
          border: solid 2px $white;
      }
    }
}