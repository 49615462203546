.page-academic-programs {
    h1.article-header {
        text-transform: inherit;
        font-size: 48px;
    }
    .left-side {
        .text-dark-blue {
            font-variation-settings: "wght" 700;
            margin-bottom: 0;
        }
        .sidebar-offset-programs {
            @media only screen and (min-width: 1429px) {
                width: 318px;
                margin-left: -70px;
            }
            .secondaryblue-bg.px-4.py-3 {
                border: 1px solid #222222;
            }
            .form-block {
                padding: 15px 17px;
                border: 1px solid #222222;
                margin-top: 12px;
                position: relative;
                margin-bottom: 60px;
                .filter-heading {
                    font-size: 16px;
                    font-family: $primary-bold-font;
                    color: $defaultblue;
                    margin-top: 15px;
                    display: none;
                }
                .custom-form {
                    .check-form-group {
                        .custom-control-label {
                            font-size: 14px;
                            padding-left: 17px;
                            font-family: $secondary-font;
                            line-height: rem-calc(16);
                        }
                        input[type=checkbox], input[type=radio] {
                            width: 17px;
                            height: 17px;
                            transform: translateY(1px);
                            &:focus, &:focus-within {
                                outline: none !important;
                            }
                        }
                        input[type=checkbox]:checked, input[type=radio]:checked {
                            border: solid 2px $defaultblue;
                            background-color: $defaultblue;
                            &:focus, &:focus-within {
                                outline: none !important;
                            }
                            &::before {
                                background-color: $white;
                            }
                        }
                        .checkbox-filters-wrap{
                            margin:20px 0;
                            .filter-subtitle{
                                font-size:rem-calc(16);
                                line-height: rem-calc(22);
                                font-weight: bold;
                                margin-bottom:10px;
                            }
                        }
                       
                    }
                    @media only screen and (max-width: 991px) {
                        button.btn.btn-secondary {
                        display: none;
                        }
                    }
                    
                }
                .clear-filter {
                    position: absolute;
                    right: 17px;
                    top: 18px;
                    font-size: 16px;
                    text-decoration: underline;
                    cursor: pointer;
                    span {
                        text-decoration: none;
                    }
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: #FFD350;
                        text-decoration-thickness: 3px;
                    }
                    @media only screen and (max-width: 991px) {
                        position: initial;
                        display: block;
                        text-align: center;
                        margin-top: 10px;
                    }
                }
                .custom-control {
                    padding: 4px 0;
                    margin-left:rem-calc(18);
                }

                @media only screen and (max-width: 991px) {
                    .mobile-dropdown-select-filter {
                        .filter-heading {
                            position: relative;
                            font-family: $secondary-font;
                            color: #222222;
                            display: block;
                            background-color: $secondaryblue;
                            border-left: solid 1px #BFBFBF;
                            border-right: solid 1px #BFBFBF;
                            border-bottom: solid 1px #BFBFBF;
                            border-top: solid 1px #BFBFBF;
                            padding: 9px 16px;
                            border-radius: 2px 2px 2px 2px;
                            font-size: 14px;
                            margin-bottom: 0;
                            cursor: pointer;
                            &::after {
                                display: inline-block;
                                margin-left: 8px;
                                vertical-align: inherit;
                                font-family: "Font Awesome 6 Free";
                                content: "\f078";
                                line-height: 0;
                                position: absolute;
                                right: 16px;
                                top: 20px;
                            }
                        }
                        .checkbox-filters-wrap {
                            padding: 0 18px 14px;
                            border-left: solid 1px $defaultblue;
                            border-right: solid 1px $defaultblue;
                            border-bottom: solid 1px $defaultblue;
                            border-radius: 0 0 2px 2px;
                            display: none;
                        }
                        &.dropdown-filter.active {
                            .filter-heading {
                                font-family: $secondary-font;
                                color: #222222;
                                display: block;
                                background-color: $white;
                                border-left: solid 1px $defaultblue;
                                border-right: solid 1px $defaultblue;
                                border-top: solid 1px $defaultblue;
                                border-bottom: none;
                                padding: 9px 16px;
                                border-radius: 2px 2px 0 0;
                                font-size: 14px;
                                margin-bottom: 0;
                                &::after {
                                    display: inline-block;
                                    margin-left: 8px;
                                    vertical-align: inherit;
                                    font-family: "Font Awesome 6 Free";
                                    content: "\f077";
                                    line-height: 0;
                                }
                            }
                            .checkbox-filters-wrap {
                                display: block;
                            }
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    padding: 0;
                    border: none;
                    margin-bottom: 40px;
                }
            }

            .mobile-filter{
                display: block;
                margin:20px 0;
                @include media-breakpoint-up(lg) {
                    display: none;
                }

                &--btn{
                    border:1px solid $off-black;
                    background-color: #F4F4F4;
                    border-radius: 3px;
                    width: 100%;
                    font-size:rem-calc(16);
                    line-height: rem-calc(21);
                    padding:7px 0;
                    color: $off-black;

                    i{
                        margin-right:7px;
                    }
                   &:hover{
                        background-color: #004B8D;
                        color:$white;

                        i{
                            color:$white;
                        }
                   }
                }
            }

            .mobile-filter-overlay{
                width: 100%;
                
                &--top{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;

                    h3{
                        color:#004B8E;
                    }

                    button{
                        width: rem-calc(103);
                        height: 34px;
                        border-radius: 3px;
                        border: 1px solid #272727;
                        color: $off-black;
                        background-color: #F4F4F4;
                        &:hover {
                            border-color: $defaultblue;
                            background-color: $defaultblue;
                            color: $white;
                        }
                    }
                }

                .clear-program-filters{
                    text-decoration: underline;
                    cursor: pointer;
                    color:#004B8E;
                    font-family: $secondary-bold-font;
                    display: block;
                    margin-top:rem-calc(22);
                    margin-bottom: rem-calc(35);
                }

                &--bottom{
                    h6{
                        color:$off-black;
                        font-family: $secondary-font !important;
                        font-weight:bold;
                        &:not(:first-child) {
                            margin-top:rem-calc(25);
                        }
                    }

                    ul{
                        padding-left:rem-calc(20);
                        list-style: none;

                        li{
                            margin:12px 0;
                            display: flex;
                            label{
                                font-size:rem-calc(14);
                                line-height: rem-calc(16);
                                font-family: $secondary-font;
                                margin-left:rem-calc(16);
                                display: flex;
                                width: 100%;
                            }
                            input[type=checkbox]{
                              /* Add if not using autoprefixer */
                              -webkit-appearance: none;
                              /* Remove most all native input styles */
                              appearance: none;
                              /* For iOS < 15 */
                              background-color: var(--form-background);
                              /* Not removed via appearance */
                              margin: 0;
                              font: inherit;
                              color: currentColor;
                              width: 17px;
                              height: 17px;
                              border: 0.15em solid currentColor;
                              border-radius: 0.15em;
                              transform: translateY(-0.075em);
                              display: inline-grid;
                              place-content: center;
                              &::before {
                                content: "";
                                width: 0.65em;
                                height: 0.65em;
                                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                                transform: scale(0);
                                transform-origin: bottom left;
                                transition: 120ms transform ease-in-out;
                                box-shadow: inset 1em 1em var(--form-control-color);
                                background-color: CanvasText;
                              }
                              &:checked {
                                border: solid 2px $defaultblue;
                                background-color: $defaultblue;
                              }
                              &:checked::before {
                                transform: scale(1);
                                background-color: $white;
                              }
                             
                            }
                        }
                    }

                    .btn.btn-outline-primary{
                        border: 2px solid #004B8E;
                        color:#004B8E;
                        font-family: $secondary-font;
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-top:rem-calc(40);
                        &:hover, &:focus, &:active {
                            background-color: #004B8E;
                            color:$white;
                            text-decoration: none;
                        }
                       
                    }
                }
            }
        }
        .form-control-wrap--search{
            position: relative;

            .clearable__clear{
                display: none;
                position: absolute;
                right:0; 
                top:3px;
                padding: 0 8px;
                font-style: bold;
                font-size: 2rem;
                user-select: none;
                cursor: pointer;
            }
        }

       
        .form-control-lg, .form-select.filters-select {
            margin-bottom: 10px;
            border-radius: 3px;
            border: solid 1px $defaultblue;
            background-color: $white;
            font-family: $secondary-font;
            font-size: 18px;
            color: #222222;
            &[type=text] {
                background-image: url(../../images/submit-arrow-mobile.svg);
                background-repeat: no-repeat;
                background-position: 98% 9px;
                background-size: 35px 35px;
                padding: 14px 48px 14px 20px;

                &:focus{
                    background-image: none !important; 
                }
            }
            &::placeholder {
                color: #222222;
                opacity: .55;
            }
        }
        .form-control-lg {
            padding: 14px 20px;
        }
        .btn.btn-primary.clear-filters {
            background-color: transparent;
            color: #3264A6;
            font-family: "Open Sans Regular";
            text-transform: capitalize;
            width: auto !important;
            margin: auto;
            padding: 8px 12px;
        }
        .form-control-lg {
            font-variation-settings: 'wght' 440;
            &::placeholder {
                color: #222222;
            }
        }
    }
    .right-side {
        p, span {
            &.found.cat-title {
                font-family: $secondary-bold-font;
                font-variation-settings: "wght" 700;
                font-size: 18px;
                font-weight: 700;
                @media only screen and (max-width: 991px) {
                    display: block;
                    margin-bottom: 15px;
                }
            }
            &.found span {
                font-style: italic;
            }
        }
        .category {
            padding: 38px 0 25px;
            border-top: 1px solid $off-black;
            margin-top: 30px;
            @media only screen and (max-width: 991px) {
                padding: 0 0 25px;
                border-top: none;
                margin-top: 0;
            }
            .category-items {
                display: inline-block;
            }
            .filter-result-item {
                background-color: $white;
                border: solid 1px #E91540;
                color: #000;
                font-weight: bold;
                padding: 1px 12px 1px 27px;
                display: inline-block;
                cursor: pointer;
                font-size: 14px;
                position: relative;
                border-radius: 60px;
                font-variation-settings: "wght" 400;
                &::after {
                    font-family: "Font Awesome 6 Free";
                    content: "\f00d";
                    color: #222222;
                    margin-left: 5px;
                    font-size: 18px;
                    position: absolute;
                    top: -2px;
                    left: 4px;
                }
                &:hover {
                    background-color: #E91540;
                    color: $white;
                    &::after {
                        color: $white;
                    }
                }
            }
        }
        p.detail {
            font-variation-settings: 'wght' 400;
            margin-bottom: 5px;
        }
        .card-text.credentials {
            font-variation-settings: "wght" 300;
        }

        .btn-group {
            .btn.btn-outline-primary {
                color: #042D61;
                border: 2px solid #042D61;
                background: transparent;
                border-radius: 4px;
                font-variation-settings: "wght" 650 !important;
                padding: 14px 41px 14px 61px;
                &::after {
                    display: inline-block;
                    margin-left: 12px;
                    vertical-align: inherit;
                    font-family: $fontawesome;
                    font-weight: 400;
                    content: "\f00c";
                    font-size: 23px;
                    line-height: 0;
                    opacity: 0;
                }
            }
            .btn-check:checked + .btn {
                color: #ffffff;
                background-color: #042D61;
                border-color: #042D61;
                &::after {
                    display: inline-block;
                    margin-left: 12px;
                    vertical-align: inherit;
                    font-family: $fontawesome;
                    font-weight: 400;
                    content: "\f00c";
                    font-size: 23px;
                    line-height: 0;
                    opacity: 1;
                }
            }
            > .btn:not(:last-child):not(.dropdown-toggle) {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                margin-right: 15px;
            }
            > .btn:nth-child(n+3) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
    }
    .card-title {
        color: $white;
    }
    .form-control-lg {
        border-radius: 0;
        font-family: "Open Sans Regular";
        font-variation-settings: 'wght' 650;
        margin-bottom: 30px;
        font-size: 16px;
        padding: 0.8rem 1rem;
        &::placeholder {
            color: #222222;
        }
    }
}

.page-academic-programs-v1 {
    .program-detail {
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .found {
        border-bottom: 1px solid #D6D6D6;
        padding: 35px 0;
        margin-bottom: 0;
    }
    .program-listing {
        border-bottom: 1px solid #D6D6D6;
        padding: 10px 20px;
        display: block;
    }
    .credentials {
        display: grid;
        align-self: center;
        justify-items: end;
    }
    .credential {
        span {
            padding: 9px 13px;
            background: #EAEAEA;
            border: 1px solid #042D61;
            border-radius: 5px;
            display: inline-block;
            color: #222222;
            font-size: 14px;
            font-weight: bold;
            margin: 0 5px;
        }
    }
    .left-side {
        padding-right: 20px;
    }
    .right-side {
        border-left: 1px solid #D6D6D6;
        padding-left: 20px;
    }
}


.page-academic-programs-v2 {

    .card.program {
        margin-bottom: 20px;
        border: none;
        .card-text {
            color: $white;
        }
        .card-body {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: #3264A6;
            color: $white;
        }
        &:hover {
            .card-body {
                bottom: 0;
                top: 0;
                .card-body-position {
                    position: absolute;
                    bottom: 0;
                    padding-bottom: 30px;
                }
            }
        }
        img.card-img-top {
            border-radius: 0;
        }
    }


    .filter-by {
        color: $white;
        display: inline-block;
        font-family: "Open Sans Regular";
        font-variation-settings: 'wght' 700;
        font-size: 18px;
    }
    .filter-controls {
        background: #042D61;
        padding: 20px 20px 40px;
        display: block;
        .filter-by, .form-select {
            margin-right: 10px;
        }
        .form-select {
            border-radius: 3px;
        }
    }
    .search-form {
        display: block;
        position: relative;
        .btn-search {
            color: #3264A6;
            background: none;
            position: absolute;
            top: 0;
            right: 0;
            padding: 14px 20px;
        }
    }
    .filter-results {
        padding: 10px 0 40px 0;
        .category {
            display: block;
            margin-bottom: 3px;
        }
        .category-items {
            display: inline-block;
        }
        .cat-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
        }
        .filter-result-item {
            background-color: #EAEAEA;
            color: $black;
            font-weight: bold;
            padding: 6px 27px 6px 12px;
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
            position: relative;
            &::after {
                font-family: $fontawesome;
                content: '\f00d';
                color: #DC3545;
                margin-left: 5px;
                font-size: 18px;
                position: absolute;
                top: 3px;
                right: 8px;
            }
        }
        .btn-clear-filters {
            font-size: 16px;
            border: 1px solid $black;
            color: #172B54;
            float: right;
            padding: 10px 12px;
            font-family: "Open Sans Regular";
            text-transform: capitalize;
            &::before {
                font-family: $fontawesome;
                content: '\f057';
                color: #DC3545;
                font-size: 18px;
                padding-right: 5px;
            }
        }
    }
}
.program-listings-wrap {
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 0;
    .program-listings-card {
        background-color: $secondaryblue;
        padding: 15px;
        outline: solid 1px #222222;
        &:hover, &:focus-within {
            outline: solid 2px $defaultblue;
            z-index: 1;
            position: relative;
            background-color: $white;
            .program-listings-title {
                a {
                    text-decoration: underline;
                    &:hover{
                        text-decoration-color: #004B8E;
                        text-decoration-thickness: 1px;
                    }
                }
            }
        }
        .program-listings-title {
            margin-bottom: 25px;
            line-height: 28px !important;
            a {
                font-size: 24px;
                color: $defaultblue;
                text-decoration: none;
            }
        
        }
        .program-listings-degree-type, .program-listings-area-of-study, .program-listings-outcome {
            font-size: rem-calc(16);
            font-family: $secondary-font;
            font-weight: normal;
            line-height: rem-calc(24);
            margin-bottom:rem-calc(5);
            span {
                font-family: $secondary-bold-font;
                font-weight: 900;
            }
        }
        .program-listings-outcome{
            line-height: rem-calc(14);
            margin-bottom:rem-calc(13);
        }
        .more-info {
            .more-less-collapse {
                text-align: right;
                margin-bottom: 0;
                .toggle-more {
                    color: $defaultblue;
                }
                a.collapsed.toggle-more {
                    color: $defaultblue;
                    &::before {
                        content: "More";
                    }
                    &::after {
                        display: inline-block;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f078";
                        line-height: 0;
                        font-size: 12px;
                    }
                    &:hover{
                        text-decoration-color: #004B8E;
                        text-decoration-thickness:1px;
                    }
                }
                a.toggle-more {

                    &::before {
                        content: "Less";
                    }
                    &::after {
                        display: inline-block;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f077";
                        line-height: 0;
                        font-size: 12px;
                    }

                    &:hover{
                        text-decoration-color: #004B8E;
                        text-decoration-thickness:1px;
                    }
                }
            }
            .card-body {
                background: transparent;
                border: none;
                padding: 0;
                a {
                  .learn-more {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border: 0;
                    }  
                }
                
            }
          
        }
    }
}