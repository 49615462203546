/*
* Font Utilities
*/
.primary-font { font-family: $primary-font; }
.primary-bold-font { font-family: $primary-bold-font; }
.secondary-font { font-family: $secondary-font; }
.secondary-bold-font { font-family: $secondary-bold-font; }
.tertiary-font { font-family: $tertiary-font; }
.tertiary-bold-font { font-family: $tertiary-bold-font; }

p {
    font-family: $secondary-font;
}
strong {
    font-variation-settings: "wght" 700;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-64 {
    font-size: 64px !important;
}

.text-yellow {
    color: $red !important;
}