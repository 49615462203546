.quick-links-list-wrap {
    background-color: $white;
    padding: 18px 11px;
    margin-bottom: 24px;
    .quick-links-list-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 13px;
            a {
                text-decoration: underline;
                color: $lightblue;
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: #FFD350;
                    text-decoration-thickness: 3px;
                }
            }
        }
    }
    &.quick-links-secondary {
        background: #F4F5F7;
        padding: 30px;
        border: solid 1px $off-black;
        margin-bottom: 30px;
        .quick-links-list-menu {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            @media only screen and (max-width: 768px) {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }
        }
    }
}