
h1, h2, h3, h4, h5, h6 {
  font-family: $primary-bold-font !important;
  color: $defaultblue;
  line-height: normal;
}

h1 {
    font-size: 48px !important;
    @media only screen and (max-width: 759px) {
        font-size: 32px !important;
    }
}
h2 {
    font-size: 32px !important;
}
h3 {
    font-size: 28px !important;
}
h4 {
    font-size: 24px !important;
}
h5 {
    font-size: 18px !important;
}
h6 {
    font-size: 16px !important;
}

.heading-border-offset {
    width: fit-content;
    position: relative;
    margin-bottom: 30px;
    &::after {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 11px;
        width: 150%;
        background-color: $red;
        z-index: 1;
    }
    span {
        position: relative;
        z-index: 2;
    }
}

.heading-border-offset-center {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    &::after {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translate(-50%);
        height: 11px;
        width: 90%;
        background-color: $red;
        z-index: 1;
    }
    span {
        position: relative;
        z-index: 2;
    }
}

.faq-heading {
    font-size: 64px;
    font-family: $primary-bold-font;
    color: $defaultblue;
    background-image: url('../../images/faq-ribbon.svg');
    background-repeat: no-repeat;
    background-size: 138px 204px;
    padding: 30px 0 0 30px;
    line-height: 72px;
    min-height: 204px;
    span {
        display: block;
        line-height: 72px;
    }
}